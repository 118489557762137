import { AT } from "../global/actionTypes";
import { Player } from "../types/types";

export const putPlayers = (players: any[]) => ({
  type: AT.PUT_PLAYERS,
  payload: players,
});
export const putPlayerDetails = (player: Player) => ({
  type: AT.PUT_PLAYERDETAILS,
  payload: player,
});

export const fetchPlayers = () => ({ type: AT.FETCH_PLAYERS });
export const fetchPlayerDetails = (player: Player) => ({
  type: AT.FETCH_PLAYERDETAILS,
  payload: player.userId,
});
