export interface Club {
  clubId: number;
  clubName: string;
  longName: string;
  shortName: string;
  thumbnail?: any;
  webIcon?: any;
}
export interface ClubDetails extends Club {
  contactEmail: string;
  contactName: string;
  contactLastName: string;
  contactAdress: string;
  mainImage?: any;
}
export interface User {
  userId: number;
  clubId: number;
  firstName: string;
  lastName: string;
  nickname: string;
  remarks: string;
  active: boolean;
  admin: boolean;
  avatar: string;
  defaultKartendeck: string;
}
export interface AuthUser extends User {
  shortName: string;
  token: string;
  expiration: Date;
}
export interface Player extends User {
  shortName: string;
  displayName: string;
  schieberStats?: SchieberStats;
  sessions?: PersonalSession[];
}
export interface SchieberStats {
  vorhandPunktetotal: number;
  gschobePunktetotal: number;
  wiisTotal: number;
  agseit: number;
  gschobe: number;
  sessions: number;
  favoritePartnerId: number;
  sessionsWon: number;
}
export interface PersonalSession {
  sessionId: number;
  started: string;
  partnerId: number;
  won: number;
  points: number;
  opponentPoints: number;
  pointsGoal: number;
  hands: number;
}

export interface Auth {
  signin: (callback: () => any) => any;
  signout: (callback: () => any) => any;
  user: User | null;
}

export interface GraphData {
  lines: DataLine[];
}
export interface DataLine {
  lineTitle: string;
  data: DataSet[];
}
export interface DataSet {
  dataSetTitle?: string;
  value: number;
}

export interface DataGridLine {
  lineTitle: string;
  data: DataGridSet[];
}
export interface DataGridSet {
  dataSetTitle?: string;
  x: number;
  y: number;
}

export enum ApiStatus {
  idle,
  loading,
  succeeded,
  failed,
  authExpired,
}

export type WiisBetrag = 20 | 50 | 100 | 150 | 200;
