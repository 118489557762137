import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectEditSchieber } from "../../../games/gamesSelector";
import { SchieberHand } from "../../../games/gamesReducer";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { RootState } from "../../../global/Store";
import { selectPlayer } from "../../../players/playerSelector";
import { SelectKachel } from "../../Common/SelectKachel";

import "./erfassen.scss";

const initNumVal = undefined as number | undefined;

interface Props {
  hand: SchieberHand;
  setWiis: (wiis: { playerId: number; amount: number; teamId: number }) => any;
}

export function RegisterSchieberHandWiiser(props: Props) {
  const session = useSelector(selectEditSchieber);
  const player1 = useSelector((state: RootState) =>
    selectPlayer(state, session.team1.player1Id)
  );
  const player2 = useSelector((state: RootState) =>
    selectPlayer(state, session.team1.player2Id)
  );
  const player3 = useSelector((state: RootState) =>
    selectPlayer(state, session.team2.player1Id)
  );
  const player4 = useSelector((state: RootState) =>
    selectPlayer(state, session.team2.player2Id)
  );
  const [open, setOpen] = useState(false);
  const [wiiser, setWiiser] = useState(initNumVal);

  const handleWiis = (val?: number) => {
    if (val) {
      props.setWiis({
        amount: val,
        playerId: wiiser as number,
        teamId:
          wiiser === session.team2.player1Id ||
          wiiser === session.team2.player2Id
            ? 2
            : 1,
      });
    }
    setOpen(false);
  };
  const handleWiiser = (val?: number) => {
    setWiiser(val);
  };
  const handleClickOpen = () => {
    setWiiser(initNumVal);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="wiise asaege" onClick={handleClickOpen}>
        Wiis
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <div style={{ fontWeight: "bolder" }}>
            {wiiser && <>Wieviel?</>}
            {!wiiser && <>Wer het gwiese?</>}
          </div>
        </DialogTitle>
        <DialogContent>
          {wiiser && (
            <>
              <SelectKachel val={20} submit={handleWiis}>
                Stöck
              </SelectKachel>
              <SelectKachel val={20} submit={handleWiis}>
                20
              </SelectKachel>
              <SelectKachel val={50} submit={handleWiis}>
                50
              </SelectKachel>
              <SelectKachel val={100} submit={handleWiis}>
                100
              </SelectKachel>
              <SelectKachel val={150} submit={handleWiis}>
                150
              </SelectKachel>
              <SelectKachel val={200} submit={handleWiis}>
                200
              </SelectKachel>
              <SelectKachel val={250} submit={handleWiis}>
                250
              </SelectKachel>
            </>
          )}
          {!wiiser && (
            <>
              <SelectKachel val={player1?.userId} submit={handleWiiser}>
                {player1?.nickname}
              </SelectKachel>
              <SelectKachel val={player2?.userId} submit={handleWiiser}>
                {player2?.nickname}
              </SelectKachel>
              <SelectKachel val={player3?.userId} submit={handleWiiser}>
                {player3?.nickname}
              </SelectKachel>
              <SelectKachel val={player4?.userId} submit={handleWiiser}>
                {player4?.nickname}
              </SelectKachel>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
