import { AT } from "../global/actionTypes";
import { ClubDetails } from "../types/types";

export const fetchClubDetails = (shortName: string) => ({
  type: AT.FETCH_CLUBDETAILS,
  payload: { shortName },
});
export const fetchCreateClub = (club: any) => ({
  type: AT.FETCH_CLUBDETAILS_ADD,
  payload: { club },
});
export const putClubdetails = (club: ClubDetails) => ({
  type: AT.PUT_CLUBDETAILS,
  payload: club,
});
export const fetchUpdateClubdetails = (club: ClubDetails) => ({
  type: AT.FETCH_CLUBDETAILS_UPDATE,
  payload: club,
});
export const announceNewClub = (club: ClubDetails) => ({
  type: AT.ANNOUNCE_NEWCLUB,
  payload: { ...club },
});
export const announceClubUpdate = (shortName: string) => ({
  type: AT.ANNOUNCE_CLUBUPDATE,
  payload: shortName,
});
