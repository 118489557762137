import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Route } from "react-router-dom";
import {
  team1sumreducerFn,
  team2sumreducerFn,
} from "../../games/gamesSelector";
import { putPlayers } from "../../players/playerActionCreators";
import { Footer } from "../Common/Footer";
import { SchieberStrichliJassTafel } from "../Spiel/SchieberStrichliJassTafel";
import { alain, karin, previewState, ueli, viola } from "./state";

export function PreviewZ() {
  const dispatch = useDispatch();

  const [game] = useState(previewState);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    dispatch(putPlayers([alain, karin, ueli, viola]));
  }, [dispatch]);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => (index > 12 ? 0 : index + 1));
    }, 3000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const current = useMemo(
    () => ({ ...game, hand: game.hand.slice(0, index) }),
    [index, game]
  );

  return (
    <>
      <div style={{ marginTop: "1em", marginBottom: "1em" }}>
        <SchieberStrichliJassTafel session={current} />
      </div>
      <Footer>
        <Route path="/*">
          <div className="j-navbar">
            <p
              style={{
                textAlign: "left",
                margin: ".5em",
                padding: ".25em",
              }}
            >
              {current.hand.reduce(team1sumreducerFn, 0)}&nbsp;&nbsp;&nbsp;
              {viola.displayName}&nbsp;&amp;&nbsp;
              {ueli.displayName}
              <span style={{ float: "right" }}>
                {karin.displayName}&nbsp;&amp;&nbsp;
                {alain.displayName}&nbsp;&nbsp;&nbsp;
                {current.hand.reduce(team2sumreducerFn, 0)}
              </span>
            </p>
          </div>
        </Route>
      </Footer>
    </>
  );
}
