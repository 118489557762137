import React, { useCallback } from "react";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlayerSwitch } from "./PlayerSwitch";

import "./erfassen.scss";
import { JSelect } from "./JSelect";
import { SchieberSession } from "../../../games/gamesReducer";

const goalOptions = [
  { value: 2500, label: "2500" },
  { value: 1000, label: "1000" },
  { value: 1500, label: "1500" },
  { value: 2000, label: "2000" },
  { value: 5000, label: "5000" },
];

interface Props {
  schieber: SchieberSession;
  setter: (part: any) => any;
  playerSetter: (playerNo: number, playerId: number) => any;
}
export function RegisterSchieberPlayerComposer(props: Props) {
  const handleChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      props.setter({ [name]: value });
    },
    [props]
  );
  return (
    <div className="teppichcontainer">
      <div className="teppich">
        <div className="teppichp1 current-player">
          <PlayerSwitch
            playerId={props.schieber?.team1?.player1Id}
            setPlayer={(playerId) => props.playerSetter(1, Number(playerId))}
            informative={Boolean(props.schieber.sessionId)}
            key={props.schieber?.team1?.player1Id}
          ></PlayerSwitch>
        </div>
        <div className="teppichp2">
          <PlayerSwitch
            playerId={props.schieber?.team2?.player1Id}
            setPlayer={(playerId) => props.playerSetter(2, Number(playerId))}
            informative={Boolean(props.schieber.sessionId)}
            key={props.schieber?.team2?.player1Id}
          ></PlayerSwitch>
        </div>
        <div className="teppichp3">
          <PlayerSwitch
            playerId={props.schieber?.team1?.player2Id}
            setPlayer={(playerId) => props.playerSetter(3, Number(playerId))}
            informative={Boolean(props.schieber.sessionId)}
            key={props.schieber?.team1?.player2Id}
          ></PlayerSwitch>
        </div>
        <div className="teppichp4">
          <PlayerSwitch
            playerId={props.schieber?.team2?.player2Id}
            setPlayer={(playerId) => props.playerSetter(4, Number(playerId))}
            informative={Boolean(props.schieber.sessionId)}
            key={props.schieber?.team2?.player2Id}
          ></PlayerSwitch>
        </div>
        <span className="p1top2">
          <FontAwesomeIcon icon={faLongArrowAltLeft} />
        </span>
        <span className="p2top3">
          <FontAwesomeIcon icon={faLongArrowAltLeft} />
        </span>
        <span className="p3top4">
          <FontAwesomeIcon icon={faLongArrowAltLeft} />
        </span>
        <span className="p4top1">
          <FontAwesomeIcon icon={faLongArrowAltLeft} />
        </span>
        <div className="teppichSelect1">
          <JSelect
            value={props.schieber?.pointsGoal}
            name={"pointsGoal"}
            setValue={handleChange}
            informative={Boolean(props.schieber.sessionId)}
            key={"pointsGoal"}
            options={goalOptions}
            className={"points-dropdown"}
          ></JSelect>
        </div>
      </div>
    </div>
  );
}
