import { AT } from "../global/actionTypes";
import { User } from "../types/types";

export const fetchLogin = (credentials: {
  nickname: string;
  password: string;
}) => ({
  type: AT.FETCH_LOGIN,
  payload: credentials,
});

export const putAuthData = (credentials: any) => ({
  type: AT.PUT_AUTH,
  payload: credentials,
});
export const putCurrentUser = (user: any) => ({
  type: AT.PUT_PERSONAL_DATA,
  payload: user,
});
export const putAuthExpired = {
  type: AT.AUTH_EXPIRED,
  payload: undefined,
};
export const deleteAuthData = {
  type: AT.DELETE_AUTH,
  payload: undefined,
};
export const deletePersonalData = {
  type: AT.DELETE_PERSONAL_DATA,
  payload: undefined,
};

export const fetchChangePassword = (data: {
  oldPassword: string;
  newPassword: string;
}) => ({
  type: AT.FETCH_CHANGE_PASSWORD,
  payload: data,
});

export const fetchUpdateUserDetails = (user: User) => ({
  type: AT.FETCH_CURRENTUSER_UPDATE,
  payload: user,
});
