import { useState } from "react";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import { Developer } from "./Developer";
import { ReduxVisualizer } from "./ReduxVisualizer";
import { ReleaseNotes } from "./ReleaseNotes";
import { Todo } from "./Todo";

export function Infos() {
  const [value, setValue] = useState("releasenotes");

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Release Notes" value="releasenotes" />
          <Tab label="Todo" value="todo" />
          <Tab label="Dev" value="dev" />
          <Tab label="Redux State" value="redux" />
        </Tabs>
      </AppBar>
      <TabPanel value="releasenotes">
        <ReleaseNotes />
      </TabPanel>
      <TabPanel value="redux">
        <ReduxVisualizer />
      </TabPanel>
      <TabPanel value="todo">
        <Todo />
      </TabPanel>
      <TabPanel value="dev">
        <Developer />
      </TabPanel>
    </TabContext>
  );
}
