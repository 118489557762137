import { AT } from "../global/actionTypes";
import { ClubDetails } from "../types/types";

export interface ClubdetailsState {
  club?: ClubDetails;
}
const initialClubsState = { club: undefined };

export const clubdetailsReducer = (
  state: ClubdetailsState = initialClubsState,
  action: any
): ClubdetailsState => {
  switch (action.type) {
    case AT.PUT_CLUBDETAILS: {
      return { ...state, club: action.payload };
    }
    case AT.ANNOUNCE_NEWCLUB: {
      return { ...state };
    }
    case AT.ANNOUNCE_CLUBUPDATE: {
      return { ...state };
    }
  }
  return { ...state };
};
