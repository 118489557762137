import React from "react";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { fetchPlayers } from "../../players/playerActionCreators";
import { useJassereiFormStyles } from "../Common/useJassereiFormStyles";
import { PromptedButton } from "../Common/PromptedButton";

export function PlayersLoader(props: any) {
  const styles = useJassereiFormStyles();
  const dispatch = useDispatch();

  const reload = () => dispatch(fetchPlayers());

  return (
    <PromptedButton
      color="default"
      className={styles.root}
      needsPrompt={false}
      okFn={reload}
      startIcon={<FontAwesomeIcon icon={faSync} />}
    >
      {props.children}
    </PromptedButton>
  );
}
