import { RootState } from "../global/Store";

export const selectApiStatus = (state: RootState) => state.apiState.apiStatus;

export const selectPendingRequests = (state: RootState) =>
  state.apiState.pending.length;

export const selectLatestResponse = (state: RootState) =>
  state.apiState.latestResponse;

export const selectBlocked = (state: RootState) => state.apiState.blocked;
