import { SessionTeam } from "../games/gamesReducer";
import { RootState } from "../global/Store";
import { Player } from "../types/types";

export const selectPlayers = (state: RootState) => state.players.data;
export const selectPlayer = (state: RootState, id?: number) =>
  state?.players?.data?.find((p) => p.userId === id) ?? undefined;
export const selectPlayerTitle = (state: RootState, id?: number) =>
  playerTitle(selectPlayer(state, id));

export const playerTitle = (player?: Player) =>
  player?.displayName ?? player?.nickname ?? player?.shortName ?? "Spieler";

export const selectTeamTitle = (state: RootState, team: SessionTeam) =>
  selectPlayerTitle(state, team.player1Id) +
  " & " +
  selectPlayerTitle(state, team.player2Id);
