import React, { useCallback, useState } from "react";
import { Button, Checkbox, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { RootState } from "../../global/Store";
import { User } from "../../types/types";
import { useJassereiFormStyles } from "../Common/useJassereiFormStyles";
import { selectUser } from "../../admin/adminSelector";
import { putUserDetails, updateUser } from "../../admin/adminActionCreators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faSave } from "@fortawesome/free-solid-svg-icons";

interface Params {
  id: string;
}
export function ManageUser() {
  const { id } = useParams<Params>();
  const user = useSelector((state: RootState) => selectUser(state, Number(id)));

  return user ? (
    <ManageUserInternal user={user}></ManageUserInternal>
  ) : (
    <div>COULD NOT FIND</div>
  );
}

interface Props {
  user: User;
}
function ManageUserInternal(props: Props) {
  const styles = useJassereiFormStyles();
  const dispatch = useDispatch();

  const [saved, setSaved] = useState(true);

  const handleChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      dispatch(
        putUserDetails({
          ...props.user,
          [name]: value,
        })
      );
      setSaved(false);
    },
    [dispatch, props.user]
  );

  const handleBoolChange = useCallback(
    (e: any) => {
      const { name, checked } = e.target;
      dispatch(
        putUserDetails({
          ...props.user,
          [name]: checked,
        })
      );
      setSaved(false);
    },
    [dispatch, props.user]
  );
  const save = useCallback(
    (e: any) => {
      dispatch(updateUser(props.user));
      setSaved(true);
    },
    [dispatch, props.user]
  );

  return (
    <>
      <h3>Benutzer bearbeiten</h3>
      <form noValidate autoComplete="off" className={styles.root}>
        <TextField
          id="firstName"
          name="firstName"
          label="Vorname"
          variant="outlined"
          onChange={handleChange}
          value={props?.user?.firstName}
        />
        <TextField
          id="lastName"
          name="lastName"
          label="Nachname"
          variant="outlined"
          onChange={handleChange}
          value={props?.user?.lastName}
        />
        <TextField
          id="nickname"
          name="nickname"
          label="Spitzname"
          variant="outlined"
          onChange={handleChange}
          value={props?.user?.nickname}
        />
        <TextField
          id="password"
          name="password"
          label="Kennwort"
          variant="outlined"
          type="password"
          onChange={handleChange}
        />
        <TextField
          id="remarks"
          name="remarks"
          label="Bemerkige"
          variant="outlined"
          onChange={handleChange}
          fullWidth
          value={props?.user?.remarks}
        />
        <Checkbox
          name="active"
          checked={props?.user?.active}
          onChange={handleBoolChange}
        />
        {"Aktiv"}
        <Checkbox
          name="admin"
          checked={props?.user?.admin}
          onChange={handleBoolChange}
        />
        {"Admin"}
        <hr />
        <Button
          className={styles.root}
          variant="contained"
          color="primary"
          onClick={save}
          disabled={saved}
          startIcon={<FontAwesomeIcon icon={faSave} />}
        >
          Speichere
        </Button>
        <Link to={"/admin/users"}>
          <Button
            className={styles.root}
            variant="contained"
            color="secondary"
            startIcon={<FontAwesomeIcon icon={faList} />}
          >
            Öbersecht
          </Button>
        </Link>
      </form>
    </>
  );
}
