import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentUiIndex,
  selectEditSchieber,
  selectIsGoalReached,
} from "../../../../games/gamesSelector";
import { SchieberHand } from "../../../../games/gamesReducer";
import { RegisterSchieberNavigatorTile } from "./RegisterSchieberNavigatorTile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faCheckCircle,
  faListUl,
} from "@fortawesome/free-solid-svg-icons";
import { setUiIndex } from "../../../../games/gamesActionCreators";

import "../erfassen.scss";

export function RegisterSchieberPaginator() {
  const dispatch = useDispatch();

  const schieber = useSelector(selectEditSchieber);
  const index = useSelector(selectCurrentUiIndex);
  const goalReached = useSelector(selectIsGoalReached);

  const goToHand = useCallback(
    (hand: SchieberHand) => dispatch(setUiIndex(hand.no - 1)),
    [dispatch]
  );
  const goToSummary = useCallback(
    (index: number) => dispatch(setUiIndex(schieber.hand.length + index)),
    [dispatch, schieber.hand.length]
  );

  return (
    <div className="nav-small">
      {schieber.hand?.map((hand) => (
        <RegisterSchieberNavigatorTile
          perRow={10}
          action={() => goToHand(hand)}
          key={hand.no}
          current={index === hand.no - 1}
        >
          {hand.no}
        </RegisterSchieberNavigatorTile>
      ))}

      {schieber.sessionId && (
        <>
          <RegisterSchieberNavigatorTile
            perRow={goalReached ? 2.5 : 10}
            empathize={goalReached}
            action={() => goToSummary(0)}
            key={"func"}
            current={index === schieber.hand.length + 0}
          >
            <FontAwesomeIcon icon={faCheckCircle} />
          </RegisterSchieberNavigatorTile>
          <RegisterSchieberNavigatorTile
            perRow={10}
            action={() => goToSummary(1)}
            key={"sum"}
            current={index === schieber.hand.length + 1}
          >
            <FontAwesomeIcon icon={faListUl} />
          </RegisterSchieberNavigatorTile>
          <RegisterSchieberNavigatorTile
            perRow={10}
            action={() => goToSummary(2)}
            key={"z"}
            current={index === schieber.hand.length + 2}
          >
            <span>Z</span>
          </RegisterSchieberNavigatorTile>
          <RegisterSchieberNavigatorTile
            perRow={10}
            action={() => goToSummary(3)}
            key={"chart"}
            current={index === schieber.hand.length + 3}
          >
            <FontAwesomeIcon icon={faChartLine} />
          </RegisterSchieberNavigatorTile>
        </>
      )}
    </div>
  );
}
