import { AT } from "../global/actionTypes";

export interface SeasonState {
  data: Season[];
}
export interface Season {
  seasonId?: number;
  name: string;
  active: boolean;
}
const initialSeason = {
  seasonId: -1,
  name: "Unbekannt",
  active: true,
};

export const seasonReducer = (
  state: SeasonState = { data: [initialSeason] },
  action: any
): SeasonState => {
  switch (action.type) {
    case AT.PUT_SEASONS: {
      return { ...state, data: action.payload };
    }
    case AT.PUT_SEASON: {
      return {
        ...state,
        data: [
          ...(state?.data?.map((s) =>
            s.seasonId === action.payload.seasonId ? action.payload : s
          ) ?? []),
        ],
      };
    }
  }
  return { ...state };
};
