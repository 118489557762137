import {
  faArrowCircleRight,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

function ClubJoin() {
  return (
    <>
      <hr />
      <Link to="/clubs">
        <h2>Het din Verein scho en Zuegang?</h2>
        <h2>
          <FontAwesomeIcon icon={faArrowCircleRight} />
          &nbsp; Portal
        </h2>
      </Link>
      <hr />
      <Link to="/create">
        <h2>
          Neue Verein &nbsp;
          <FontAwesomeIcon icon={faPlusCircle} />
        </h2>
      </Link>
    </>
  );
}
export default ClubJoin;
