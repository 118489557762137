import React from "react";
import "../../App.scss";

export function Nav(props: any) {
  return (
    <div>
      <nav
        className={
          "j-navbar navbar navbar-expand-sm navbar-toggleable-sm navbar-light border-bottom box-shadow"
        }
      >
        <div className={"container"}>{props.children}</div>
      </nav>
    </div>
  );
}
