import { RootState } from "../global/Store";
import { Season } from "../seasons/seasonReducer";
import { User } from "../types/types";

export const selectUser = (state: RootState, userId: number) =>
  state.admin?.users?.find((u: User) => u.userId === userId);
export const selectUsers = (state: RootState) => state.admin?.users;
export const selectUserByName = (state: RootState, nickname: string) =>
  state.admin?.users?.find(
    (u: User) => u.nickname?.toUpperCase() === nickname?.toUpperCase()
  );

export const selectSeason = (state: RootState, seasonId: number) =>
  state.seasons.data?.find((u: Season) => u.seasonId === seasonId);
