import { SchieberSession } from "../../../games/gamesReducer";
import { SchieberTimelineHeader } from "./SchieberTimelineHeader";

import "../timeline.scss";
import { SchieberTimelineHand2 } from "./SchieberTimelineHand2";
import { SchieberTimelineSummary2 } from "./SchieberTimelineSummary2";
import { SchieberTimelineTeams2 } from "./SchieberTimelineTeams2";

interface Props {
  session: SchieberSession;
}
export function SchieberTimeline2(props: Props) {
  return (
    <>
      <table
        style={{
          width: "100%",
          tableLayout: "fixed",
        }}
      >
        <thead>
          <tr>
            <th className="timeline-betragspalte"></th>
            <th style={{ width: "1.2em" }}></th>
            <th></th>
            <th className="timeline-betragspalte"></th>
            <th className="timeline-betragspalte"></th>
          </tr>
        </thead>
        <tbody>
          <SchieberTimelineHeader {...props} colSpan={5} />
          <SchieberTimelineTeams2 {...props} />
          {props?.session?.hand?.map((hand) => (
            <SchieberTimelineHand2 hand={hand} key={hand.no} />
          ))}
          <SchieberTimelineSummary2 {...props} />
        </tbody>
      </table>
    </>
  );
}
