import { AT } from "../global/actionTypes";
import { ApiStatus } from "../types/types";

export interface ApiState {
  pending: { id: string; url: string }[];
  blocked: boolean;
  apiStatus: ApiStatus;
  latestResponse?: { message: string; severity: string };
}
const initialState = {
  pending: [],
  blocked: false,
  apiStatus: ApiStatus.idle,
};

export const apiReducer = (
  state: ApiState = initialState,
  action: any
): ApiState => {
  switch (action.type) {
    // these operations are critical, hence toggle blocked (backdrop)
    case AT.FETCH_SCHIEBERDETAILS:
    case AT.LOCK_SCHIEBERMETADATA: {
      return {
        ...state,
        blocked: true,
      };
    }
    case AT.FETCH_PENDING: {
      return {
        ...state,
        apiStatus: ApiStatus.loading,
        pending: [
          ...state.pending,
          { id: action.payload.id, url: action.payload.url },
        ],
      };
    }
    case AT.FETCH_FULFILLED: {
      return {
        ...state,
        blocked: false,
        apiStatus: ApiStatus.succeeded,
        pending: state.pending.filter((i) => i.id !== action.payload.id),
      };
    }
    case AT.FETCH_REJECTED: {
      return {
        ...state,
        blocked: false,
        apiStatus: ApiStatus.failed,
        pending: state.pending.filter((i) => i.id !== action.payload.id),
      };
    }
    case AT.FETCH_RESPONSE: {
      return {
        ...state,
        blocked: false,
        latestResponse: action.payload,
      };
    }
    case AT.AUTH_EXPIRED: {
      return { ...state, apiStatus: ApiStatus.authExpired };
    }
    case AT.ANNOUNCE_APPROVAL: {
      return {
        ...state,
        blocked: false,
        latestResponse: {
          message: "Jassette esch gspeicheret",
          severity: "success",
        },
      };
    }
  }
  return { ...state, blocked: false };
};
