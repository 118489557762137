import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  approveSchieberSessionTeam1,
  approveSchieberSessionTeam2,
} from "../../../games/gamesActionCreators";
import { SchieberSession } from "../../../games/gamesReducer";
import {
  selectTeam1GoalReached,
  selectTeam1Title,
  selectTeam2GoalReached,
  selectTeam2Title,
} from "../../../games/gamesSelector";
import { Prompter } from "../../Common/Prompter";

interface Props {
  schieber: SchieberSession;
}
export function FinishSchieberRegister(props: Props) {
  const [openPrompt, setOpenPrompt] = useState(0);

  const dispatch = useDispatch();

  const okFn = useCallback(() => {
    if (openPrompt === 1) {
      dispatch(approveSchieberSessionTeam1(props.schieber.sessionId));
    }
    if (openPrompt === 2) {
      dispatch(approveSchieberSessionTeam2(props.schieber.sessionId));
    }
    setOpenPrompt(0);
  }, [dispatch, openPrompt, props.schieber.sessionId]);

  const team1Fn = () => (team1GoalReached ? setOpenPrompt(1) : {});
  const team2Fn = () => (team2GoalReached ? setOpenPrompt(2) : {});

  const team1GoalReached = useSelector(selectTeam1GoalReached);
  const team2GoalReached = useSelector(selectTeam2GoalReached);
  const team1 = useSelector(selectTeam1Title);
  const team2 = useSelector(selectTeam2Title);

  const classNames1 = useMemo(
    () => "finissiere " + (team1GoalReached ? "" : "notfinissiert"),
    [team1GoalReached]
  );
  const classNames2 = useMemo(
    () => "finissiere " + (team2GoalReached ? "" : "notfinissiert"),
    [team2GoalReached]
  );
  return (
    <div style={{ display: "block" }}>
      {team1GoalReached || team2GoalReached ? (
        <h2>Wer het gwonne?</h2>
      ) : (
        <h2>No niemert het s Ziel erreicht.</h2>
      )}
      <div className={classNames1} onClick={team1Fn}>
        {team1}
      </div>
      <div className={classNames2} onClick={team2Fn}>
        {team2}
      </div>
      <Prompter
        promptText={"Wotsch d Igab bestätige ond d Erfassig beende?"}
        okFn={okFn}
        cancelFn={() => setOpenPrompt(0)}
        open={openPrompt > 0}
        okText={"Jawohl"}
        cancelText={"Abbreche"}
      />
    </div>
  );
}
