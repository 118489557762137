import React, { useCallback, useState } from "react";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";
import { useJassereiFormStyles } from "./useJassereiFormStyles";
import { fetchPost } from "../../util/apiUtil";
import { Image } from "./Image";
import { resizeImage } from "./Files/resizeImage";

interface Properties {
  additionalParams?: [{ key: string; value: string }];
  url: string;
  callBackFn: (guid: string) => any;
  disabled?: boolean;
  mediaGuid?: string;
  children?: any;
  size: "s" | "m" | "l" | "xl" | "max";
}
export function UploadImage(props: Properties) {
  const classes = useJassereiFormStyles();
  const [errorMsg, setErrorMsg] = useState(undefined as string | undefined);

  const selectFile = useCallback(
    (event: any) => {
      setErrorMsg(undefined);
      const file = event.target.files[0];
      if (!file?.name) {
        setErrorMsg("Keis File usgwählt");
        return;
      }
      resizeImage(file, props.size, (uri: any) => {
        console.warn(uri);
        const formData = new FormData();
        formData.append("File", uri, file.name);
        props.additionalParams?.forEach((p) => formData.append(p.key, p.value));

        fetchPost(props.url, formData).action().then(props.callBackFn);
      });
    },
    [props.additionalParams, props.callBackFn, props.size, props.url]
  );

  return (
    <div
      style={{
        borderRadius: ".5em",
        border: "2px solid",
        borderColor: "lightgrey",
        padding: ".25em",
        marginBottom: "1em",
      }}
    >
      <Button
        disabled={props.disabled}
        className={classes.root}
        variant="contained"
        color="primary"
        component="label"
        startIcon={<FontAwesomeIcon icon={faImage} />}
      >
        {props.children}
        <input
          type="file"
          hidden
          accept=".gif, .jpg, .png, .bmp, .jpeg"
          onChange={selectFile}
        />
      </Button>
      {errorMsg ? (
        <div>{errorMsg}</div>
      ) : (
        props.mediaGuid && (
          <div className={"upload-preview-container"}>
            <Image size={props.size} guid={props.mediaGuid} />
          </div>
        )
      )}
    </div>
  );
}
