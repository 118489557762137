import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchPlayers } from "../../players/playerActionCreators";
import { selectPlayers } from "../../players/playerSelector";
import { Player } from "../../types/types";

export function Players() {
  const dispatch = useDispatch();

  const players = useSelector(selectPlayers);

  useEffect(() => {
    dispatch(fetchPlayers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return players ? (
    <PlayersInternal players={players}></PlayersInternal>
  ) : (
    <div>Keine Spieler gefunden</div>
  );
}

interface Props {
  players: Player[];
}
function PlayersInternal(props: Props) {
  const history = useHistory();

  const gotoDetails = useCallback(
    (player: Player) => {
      history.push(`/player/${player.userId}`);
    },
    [history]
  );

  return (
    <table className="table table-striped" aria-labelledby="tableLabel">
      <thead>
        <tr>
          <th></th>
          <th className="d-table-cell">Speler</th>
          <th className="d-none d-sm-table-cell">Vorname</th>
          <th className="d-none d-sm-table-cell">Nochname</th>
          <th className="d-none d-sm-table-cell">Sessions</th>
          <th className="d-none d-sm-table-cell">Agseit</th>
          <th className="d-none d-sm-table-cell">Gschobe</th>
          <th className="d-none d-sm-table-cell">Gwise</th>
        </tr>
      </thead>
      <tbody>
        {props.players.map((player: Player) => (
          <tr onClick={() => gotoDetails(player)} key={player.userId}>
            <td>
              <span className="badge badge-pill badge-primary">
                {player.shortName}
              </span>
            </td>
            <td className="d-none d-sm-table-cell">{player.nickname}</td>
            <td className="d-table-cell d-sm-none">{player.displayName}</td>
            <td className="d-none d-sm-table-cell">{player.firstName}</td>
            <td className="d-none d-sm-table-cell">{player.lastName}</td>
            <td className="d-none d-sm-table-cell">
              {player.schieberStats?.sessions}
            </td>
            <td className="d-none d-sm-table-cell">
              {player.schieberStats?.agseit} (
              {player.schieberStats?.vorhandPunktetotal})
            </td>
            <td className="d-none d-sm-table-cell">
              {player.schieberStats?.gschobe} (
              {player.schieberStats?.gschobePunktetotal})
            </td>
            <td className="d-none d-sm-table-cell">
              {player.schieberStats?.wiisTotal}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
