import { all, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  approveSchieber,
  approveSchieberDraft,
  createSchieber,
  createSchieberDraft,
  deleteSchieber,
  loadSchieber,
  loadSchieberDrafts,
  loadSchiebers,
} from "../api/schieberApi";
import { AT } from "../global/actionTypes";
import { watchApiCall } from "../util/apiUtil";
import {
  putApprovalFromServer,
  putCurrentSchieber,
  putSchieberDetails,
  putSchieberDraftFromServer,
  putSchieberDrafts,
  putSchieberFromServer,
  putSchiebers,
} from "./gamesActionCreators";
import { history } from "../global/Store";
import { pendingChanges, selectEditSchieber } from "./gamesSelector";
import { SchieberSession, initialSchieberCreator } from "./gamesReducer";
import { createResponseMessage } from "../api/apiActionCreator";

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export function* fetchSchiebersSaga() {
  yield watchApiCall(loadSchiebers(), putSchiebers);
}
export function* watchFetchSchiebersSaga() {
  yield takeLatest(AT.FETCH_SCHIEBERS, fetchSchiebersSaga);
}

export function* fetchDraftsSaga() {
  yield watchApiCall(loadSchieberDrafts(), putSchieberDrafts);
}
export function* watchFetchDraftsSaga() {
  yield takeLatest(AT.FETCH_DRAFTS, fetchDraftsSaga);
}
export function* watchPutAuth() {
  yield takeLatest(AT.PUT_AUTH, fetchDraftsSaga);
}

export function* fetchSchieberSaga(sessionId: string): any {
  yield watchApiCall(loadSchieber(sessionId), putCurrentSchieber);
}
export function* fetchSchieberDetailsSaga(sessionId: string): any {
  yield watchApiCall(loadSchieber(sessionId), putSchieberDetails);
}
export function* watchFetchSchieberSaga() {
  yield takeLatest(AT.FETCH_SCHIEBER, (data: any) =>
    fetchSchieberSaga(data.payload)
  );
}
export function* watchFetchSchieberDetailsSaga() {
  yield takeLatest(AT.FETCH_SCHIEBERDETAILS, (data: any) =>
    fetchSchieberDetailsSaga(data.payload)
  );
}

export function* saveSession() {
  const session: SchieberSession = yield select(selectEditSchieber);
  yield watchApiCall(createSchieber(session), putSchieberFromServer);
}

export function* saveSchieberSession() {
  const session: SchieberSession = yield select(selectEditSchieber);
  yield watchApiCall(createSchieber(session), putSchieberFromServer);
}
export function* watchSaveSchieberSession() {
  yield takeLatest(AT.LOCK_SCHIEBERMETADATA, saveSchieberSession);
}

export function* deleteSchieberSession() {
  const session: SchieberSession = yield select(selectEditSchieber);
  if (session?.sessionId) {
    yield watchApiCall(
      deleteSchieber(session.sessionId as number),
      createResponseMessage("Gelöscht", "Konnte nicht löschen")
    );
  }
}
export function* watchDeleteSchieberSession() {
  yield takeLatest(AT.DELETE_SCHIEBERSESSION, deleteSchieberSession);
}

export function* saveSchieberDraft() {
  const session: SchieberSession = yield select(selectEditSchieber);
  yield watchApiCall(createSchieberDraft(session), putSchieberDraftFromServer);
}
export function* watchSaveSchieberDraft() {
  yield takeLatest(AT.SAVE_SCHIEBERSESSION, saveSchieberDraft);
}

export function* syncSchieberDraft() {
  while (true) {
    yield delay(10000);
    const mustSave: boolean = yield select(pendingChanges);
    if (mustSave) {
      yield saveSchieberDraft();
    }
  }
}

export function* approveDraft(action: any) {
  yield watchApiCall(
    approveSchieberDraft(action.payload),
    putApprovalFromServer
  );
}
export function* watchApproveDraft() {
  yield takeEvery(AT.APPROVE_SCHIEBERSESSIONDRAFT, approveDraft);
}

export function* approveSession(action: any) {
  yield watchApiCall(approveSchieber(action.payload), putApprovalFromServer);
}
export function* watchApproveSession() {
  yield takeEvery(AT.APPROVE_SCHIEBERSESSION, approveSession);
}

export function* announceApproval() {
  yield history.push("/schiebers");
  yield put(putCurrentSchieber(initialSchieberCreator()));
}
export function* watchAnnounceApproval() {
  yield takeLatest(AT.ANNOUNCE_APPROVAL, announceApproval);
}

export function* handleSchieberAnnouncement() {
  yield history.push("/erfassen");
  yield saveSchieberDraft();
}
export function* watchSchieberUpdateFromServer() {
  yield takeLatest(AT.ANNOUNCE_SCHIEBER, handleSchieberAnnouncement);
}

export default function* rootGamesSaga() {
  yield all([
    watchFetchSchiebersSaga(),
    watchFetchSchieberSaga(),
    watchFetchSchieberDetailsSaga(),
    watchSaveSchieberSession(),
    watchDeleteSchieberSession(),
    watchSaveSchieberDraft(),
    watchFetchDraftsSaga(),
    watchApproveDraft(),
    watchApproveSession(),
    watchAnnounceApproval(),
    watchSchieberUpdateFromServer(),
    watchPutAuth(),
    syncSchieberDraft(),
  ]);
}
