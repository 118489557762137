import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRandom } from "@fortawesome/free-solid-svg-icons";
import { SchieberHand } from "../../../games/gamesReducer";

import "./erfassen.scss";

interface Props {
  hand: SchieberHand;
  schiebe: () => any;
}
export function RegisterSchieberHandSchieber(props: Props) {
  const classNames =
    "asaege " + (props.hand.playerGschobeId ? "gschobe" : "schiebe");

  return (
    <div className={classNames} onClick={props.schiebe}>
      <FontAwesomeIcon icon={faRandom} />
    </div>
  );
}
