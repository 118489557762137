import { all, put, takeLatest } from "redux-saga/effects";
import { AT } from "../global/actionTypes";
import { watchApiCall } from "../util/apiUtil";
import { authenticate, changePassword, updateUser } from "../api/authApi";
import { putAuthData, putCurrentUser } from "./userActionCreators";
import { createResponseMessage } from "../api/apiActionCreator";
import { currentSubDomain } from "../util/commonUtil";

export function* fetchLoginSaga(data: any) {
  const shortName = currentSubDomain();
  yield watchApiCall(authenticate({ ...data, shortName }), putAuthData);
}
export function* watchFetchLoginSaga() {
  yield takeLatest(AT.FETCH_LOGIN, (data: any) => fetchLoginSaga(data.payload));
}

export function* fetchChangePasswordSaga(data: any) {
  yield watchApiCall(
    changePassword(data),
    createResponseMessage("Kennwort esch gänderet", "Ned chöne ändere")
  );
}
export function* watchFetchChangePasswordSaga() {
  yield takeLatest(AT.FETCH_CHANGE_PASSWORD, (data: any) =>
    fetchChangePasswordSaga(data.payload)
  );
}

export function* fetchUpdateCurrentUserSaga(data: any) {
  yield watchApiCall(updateUser(data), putCurrentUser, (result: any) =>
    put(createResponseMessage("Date sind apasst", "Ned chöne apasse")(result))
  );
}
export function* watchFetchUpdateCurrentUserSaga() {
  yield takeLatest(AT.FETCH_CURRENTUSER_UPDATE, (data: any) =>
    fetchUpdateCurrentUserSaga(data.payload)
  );
}

export default function* rootUserSaga() {
  yield all([
    watchFetchLoginSaga(),
    watchFetchChangePasswordSaga(),
    watchFetchUpdateCurrentUserSaga(),
  ]);
}
