import React from "react";
import { SchieberSession } from "../../../games/gamesReducer";

interface Props {
  session: SchieberSession;
  colSpan?: number;
}
export function SchieberTimelineHeader(props: Props) {
  return (
    <tr className="timeline-header">
      <th colSpan={props.colSpan ?? 3}>
        {props.session.remarks} ({props.session.pointsGoal})
      </th>
    </tr>
  );
}
