import { all, takeEvery, takeLatest } from "redux-saga/effects";
import { loadPlayerDetails, loadPlayers } from "../api/playersApi";
import { AT } from "../global/actionTypes";
import { watchApiCall } from "../util/apiUtil";
import { putPlayerDetails, putPlayers } from "./playerActionCreators";

export function* fetchPlayersSaga() {
  yield watchApiCall(loadPlayers(), putPlayers);
}
export function* watchFetchPlayersSaga() {
  yield takeEvery(AT.FETCH_PLAYERS, fetchPlayersSaga);
}

export function* fetchPlayerDetailsSaga(playerId: number) {
  yield watchApiCall(loadPlayerDetails(playerId), putPlayerDetails);
}
export function* watchFetchPlayerDetailsSaga() {
  yield takeLatest(AT.FETCH_PLAYERDETAILS, (action: any) =>
    fetchPlayerDetailsSaga(action.payload)
  );
}

export default function* rootPlayerSaga() {
  yield all([watchFetchPlayersSaga(), watchFetchPlayerDetailsSaga()]);
}
