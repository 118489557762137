import { AT } from "../global/actionTypes";
import { User } from "../types/types";

export interface AdminState {
  users: User[];
}
const intialAdminState = { users: [] };

export const adminReducer = (
  state: AdminState = intialAdminState,
  action: any
): AdminState => {
  switch (action.type) {
    case AT.DELETE_PERSONAL_DATA: {
      return intialAdminState;
    }
    case AT.PUT_USERS: {
      return { ...state, users: action.payload };
    }
    case AT.PUT_USERDETAILS: {
      return {
        ...state,
        users: [
          ...(state?.users?.map((u) =>
            u.userId === action.payload.userId ? action.payload : u
          ) ?? []),
        ],
      };
    }
    case AT.ANNOUNCE_NEWUSER: {
      return { ...state, users: [...state.users, action.payload] };
    }
  }
  return { ...state };
};
