import { makeStyles } from "@material-ui/core/styles";

export const useJassereiFormStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  card: {
    marginBottom: theme.spacing(4),
  },
  playerdropdown: {
    width: "100%",
  },
  registerTextfield: {
    minWidth: "16em",
  },
  simpleCreateTextfield: {
    width: "10em",
    marginRight: "1em",
  },
  overViewTable: {
    marginTop: "2em",
  },
  fullWidth: {
    width: "100%",
  },
  bold: {
    fontWeight: "bold",
  },
  jasstafel: {
    textAlign: "center",
    position: "absolute",
  },
}));
