import React from "react";
import "../../App.scss";
import { Link } from "react-router-dom";

export function NavTitle(props: any) {
  return (
    <Link className="j-navbar-title" to="/">
      {props.children}
    </Link>
  );
}
