import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { putAttachment } from "../../../games/gamesActionCreators";
import { selectEditSchieber } from "../../../games/gamesSelector";
import { UploadImage } from "../../Common/UploadImage";
import { CancelSchieberRegister } from "./CancelSchieberRegister";
import { FinishSchieberRegister } from "./FinishSchieberRegister";

export function RegisterSchieberSummary() {
  const dispatch = useDispatch();

  const session = useSelector(selectEditSchieber);

  const put = useCallback(
    (guid: string) => {
      dispatch(putAttachment(guid));
      console.warn("Ahang esch aghänkt"); // TODO: richtige meldung
    },
    [dispatch]
  );
  return (
    <>
      <FinishSchieberRegister schieber={session} />
      <div
        style={{
          marginTop: "1em",
          display: "block",
          float: "left",
          width: "100%",
          textAlign: "center",
        }}
      >
        {session?.sessionId && (
          <UploadImage
            callBackFn={put}
            additionalParams={[
              { key: "AssignedId", value: String(session?.sessionId) },
            ]}
            url={"SchieberSessions/Upload"}
            disabled={!session?.sessionId}
            mediaGuid={session?.attachment}
            size="xl"
          >
            Anhang hinzufügen
          </UploadImage>
        )}

        <CancelSchieberRegister>Entwurf lösche</CancelSchieberRegister>
      </div>
    </>
  );
}
