import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import { ApiState, apiReducer } from "../api/apiReducer";
import { GamesState, gamesReducer } from "../games/gamesReducer";
import rootGamesSaga from "../games/gamesSagas";
import { PlayerState, playerReducer } from "../players/playerReducer";
import rootPlayerSaga from "../players/playerSagas";
import { SeasonState, seasonReducer } from "../seasons/seasonReducer";
import rootSeasonSaga from "../seasons/seasonSagas";
import { UserState, userReducer } from "../user/userReducer";
import rootUserSaga from "../user/userSaga";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import rootAdminSaga from "../admin/adminSaga";
import { AdminState, adminReducer } from "../admin/adminReducer";
import { persistStore } from "redux-persist";
import storage from "redux-persist/es/storage";
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";
import persistReducer from "redux-persist/lib/persistReducer";
import { ClubsState, clubsReducer } from "../clubs/clubsReducer";
import {
  ClubdetailsState,
  clubdetailsReducer,
} from "../clubdetails/clubdetailsReducer";
import rootClubdetailsSaga from "../clubdetails/clubdetailsSaga";
import rootClubsSaga from "../clubs/clubsSaga";

const persistConfig = {
  storage,
  stateReconciler: autoMergeLevel1,
};
const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    seasons: persistReducer<SeasonState>(
      { ...persistConfig, key: "seasons" },
      seasonReducer
    ),
    apiState: apiReducer,
    players: persistReducer<PlayerState>(
      { ...persistConfig, key: "players" },
      playerReducer
    ),
    games: persistReducer<GamesState>(
      { ...persistConfig, key: "games" },
      gamesReducer
    ),
    user: persistReducer<UserState>(
      { ...persistConfig, key: "user" },
      userReducer
    ),
    admin: adminReducer,
    clubs: clubsReducer,
    clubdetails: persistReducer<ClubdetailsState>(
      { ...persistConfig, key: "clubdetails" },
      clubdetailsReducer
    ),
  });

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

const saga = createSagaMiddleware();
const router = routerMiddleware(history);
const middleWares = [saga, router];

const store = createStore(
  createRootReducer(history),
  composeEnhancers(applyMiddleware(...middleWares))
);

saga.run(rootSaga);

function* rootSaga() {
  yield all([
    rootSeasonSaga(),
    rootPlayerSaga(),
    rootGamesSaga(),
    rootUserSaga(),
    rootAdminSaga(),
    rootClubsSaga(),
    rootClubdetailsSaga(),
  ]);
}

export type RootState = {
  router: any;
  apiState: ApiState;
  seasons: SeasonState;
  players: PlayerState;
  games: GamesState;
  user: UserState;
  admin: AdminState;
  clubs: ClubsState;
  clubdetails: ClubdetailsState;
};

export const persistor = persistStore(store);

export default store;
