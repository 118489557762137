export const AT = {
  PUT_AUTH: "PUT_AUTH",
  DELETE_AUTH: "DELETE_AUTH",
  AUTH_EXPIRED: "AUTH_EXPIRED",
  PUT_PERSONAL_DATA: "PUT_PERSONAL_DATA",
  DELETE_PERSONAL_DATA: "DELETE_DELETE_PERSONAL_DATAAUTH",

  /* ------------ State Content Actions ------------- */
  PUT_CLUBS: "PUT_CLUBS",

  ANNOUNCE_NEWCLUB: "ANNOUNCE_NEWCLUB",

  PUT_CLUBDETAILS: "PUT_CLUBDETAILS",
  ANNOUNCE_CLUBUPDATE: "ANNOUNCE_CLUBUPDATE",

  PUT_SEASONS: "PUT_SEASONS",
  PUT_SEASON: "PUT_SEASON",
  ANNOUNCE_NEWSEASON: "ANNOUNCE_NEWSEASON",

  PUT_PLAYERS: "PUT_PLAYERS",
  PUT_PLAYERDETAILS: "PUT_PLAYERDETAILS",

  PUT_SCHIEBERS: "PUT_SCHIEBERS",

  PUT_USERS: "PUT_USERS",
  PUT_USERDETAILS: "PUT_USERDETAILS",
  ANNOUNCE_NEWUSER: "ANNOUNCE_NEWUSER",

  PUT_SCHIEBERPLAYER: "PUT_SCHIEBERPLAYER",
  PUT_SCHIEBERFORM: "PUT_SCHIEBERFORM",

  PUT_SCHIEBER: "PUT_SCHIEBER",

  PUT_SCHIEBERDETAILS: "PUT_SCHIEBERDETAILS",

  PUT_SCHIEBERDRAFTS: "PUT_SCHIEBERDRAFTS",

  ANNOUNCE_SCHIEBER: "ANNOUNCE_SCHIEBER",
  ANNOUNCE_SCHIEBERDRAFT: "ANNOUNCE_SCHIEBERDRAFT",
  ANNOUNCE_APPROVAL: "ANNOUNCE_APPROVAL",

  LOCK_SCHIEBERMETADATA: "LOCK_SCHIEBERMETADATA",
  APPROVE_SCHIEBERSESSION: "APPROVE_SCHIEBERSESSION",
  APPROVE_SCHIEBERSESSIONDRAFT: "APPROVE_SCHIEBERSESSIONDRAFT",

  ADD_SCHIEBERHAND: "ADD_SCHIEBERHAND",
  REMOVE_SCHIEBERHAND: "REMOVE_SCHIEBERHAND",
  SAVE_SCHIEBERSESSION: "SAVE_SCHIEBERSESSION",
  DELETE_SCHIEBERSESSION: "DELETE_SCHIEBERSESSION",
  DO_SCHIEBE: "DO_SCHIEBE",
  DO_ASAEGE: "DO_ASAEGE",
  DO_CHOOSEVARIANTE: "DO_CHOOSEVARIANTE",
  ADD_SCHIEBERWEIS: "ADD_SCHIEBERWEIS",
  REMOVE_SCHIEBERWEIS_TEAM1: "REMOVE_SCHIEBERWEIS_TEAM1",
  REMOVE_SCHIEBERWEIS_TEAM2: "REMOVE_SCHIEBERWEIS_TEAM2",
  ADD_SCHIEBERHANDPOINTS: "ADD_SCHIEBERHANDPOINTS",
  PUT_SESSIONATTACHMENT: "PUT_SESSIONATTACHMENT",

  /* ------------ GUI State Actions ------------- */
  SET_UIINDEX: "SET_UIINDEX",

  /* ------------ API Actions ------------- */
  FETCH_LOGIN: "FETCH_LOGIN",

  FETCH_CHANGE_PASSWORD: "FETCH_CHANGE_PASSWORD",
  FETCH_CURRENTUSER_UPDATE: "FETCH_CURRENTUSER_UPDATE",

  FETCH_CLUBS: "FETCH_CLUBS",

  FETCH_CLUBDETAILS_ADD: "FETCH_CLUBDETAILS_ADD",

  FETCH_CLUBDETAILS: "FETCH_CLUBDETAILS",
  FETCH_CLUBDETAILS_UPDATE: "FETCH_CLUBDETAILS_UPDATE",

  FETCH_SEASONS: "FETCH_SEASONS",
  FETCH_SEASON_ADD: "FETCH_SEASON_ADD",
  FETCH_SEASON_UPDATE: "FETCH_SEASON_UPDATE",

  FETCH_PLAYERS: "FETCH_PLAYERS",
  FETCH_PLAYERDETAILS: "FETCH_PLAYERDETAILS",

  FETCH_SCHIEBERS: "FETCH_SCHIEBERS",
  FETCH_SCHIEBER: "FETCH_SCHIEBER",
  FETCH_SCHIEBERDETAILS: "FETCH_SCHIEBERDETAILS",

  FETCH_DRAFTS: "FETCH_DRAFTS",

  FETCH_USERS: "FETCH_USERS",
  FETCH_USER_CREATE: "FETCH_USER_CREATE",
  FETCH_USER_UPDATE: "FETCH_USER_UPDATE",

  /* ------------ API State Changes ------------- */
  FETCH_PENDING: "FETCH_PENDING",
  FETCH_FULFILLED: "FETCH_FULFILLED",
  FETCH_REJECTED: "FETCH_REJECTED",
  FETCH_RESPONSE: "FETCH_RESPONSE",
};
