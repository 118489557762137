import React, { useState } from "react";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import { ManageUsers } from "./ManageUsers";
import { useParams } from "react-router-dom";
import { CreateUser } from "./CreateUser";
import { ManageSeasons } from "./ManageSeasons";
import { CreateSeason } from "./CreateSeason";
import { Infos } from "./Infos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ManageClub } from "./ManageClub";

interface Params {
  tab: string;
}
export function Admin() {
  const { tab } = useParams<Params>();
  const [value, setValue] = useState(tab ?? "users");

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Benutzer" value="users" />
          <Tab label="Saisons" value="seasons" />
          <Tab label="Verein" value="club" />
          <Tab label={<FontAwesomeIcon icon={faInfoCircle} />} value="info" />
        </Tabs>
      </AppBar>
      <TabPanel value="users">
        <CreateUser />
        <ManageUsers />
      </TabPanel>
      <TabPanel value="seasons">
        <CreateSeason />
        <ManageSeasons />
      </TabPanel>
      <TabPanel value="club">
        <ManageClub />
      </TabPanel>
      <TabPanel value="info" style={{ padding: "0" }}>
        <Infos />
      </TabPanel>
    </TabContext>
  );
}
