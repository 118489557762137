import { AT } from "../global/actionTypes";
import { SchieberHand, SchieberSession } from "./gamesReducer";

export const setUiIndex = (index: number) => ({
  type: AT.SET_UIINDEX,
  payload: index,
});
export const putSchiebers = (schiebers: SchieberSession[]) => ({
  type: AT.PUT_SCHIEBERS,
  payload: schiebers,
});
export const putSchieberDetails = (schieber: SchieberSession) => ({
  type: AT.PUT_SCHIEBERDETAILS,
  payload: populateInternalSchieber(schieber),
});
export const putCurrentSchieber = (schieber: SchieberSession) => ({
  type: AT.PUT_SCHIEBER,
  payload: populateInternalSchieber(schieber),
});

export const putSchieberFromServer = (schieber: SchieberSession) => ({
  type: AT.ANNOUNCE_SCHIEBER,
  payload: schieber,
});
export const putSchieberDraftFromServer = (schieber: SchieberSession) => ({
  type: AT.ANNOUNCE_SCHIEBERDRAFT,
  payload: schieber,
});
export const putApprovalFromServer = (schieber: SchieberSession) => ({
  type: AT.ANNOUNCE_APPROVAL,
  payload: schieber,
});
export const approveSchieberSession = (sessionId: any) => ({
  type: AT.APPROVE_SCHIEBERSESSION,
  payload: { id: sessionId },
});
export const approveSchieberSessionTeam1 = (sessionId: any) => ({
  type: AT.APPROVE_SCHIEBERSESSION,
  payload: { id: sessionId, team1Won: true },
});
export const approveSchieberSessionTeam2 = (sessionId: any) => ({
  type: AT.APPROVE_SCHIEBERSESSION,
  payload: { id: sessionId, team1Won: false },
});

export const approveSchieberSessionDraft = (draftId: any) => ({
  type: AT.APPROVE_SCHIEBERSESSIONDRAFT,
  payload: { id: draftId },
});

export const schiebe = (hand: SchieberHand, playerGschobeId?: number) => ({
  type: AT.DO_SCHIEBE,
  payload: { handNo: hand.no, playerGschobeId },
});
export const asaege = (
  hand: SchieberHand,
  team1Points: number,
  team2Points: number
) => ({
  type: AT.DO_ASAEGE,
  payload: { handNo: hand.no, team1Points, team2Points },
});
export const putVariante = (hand: SchieberHand, variante: number) => ({
  type: AT.DO_CHOOSEVARIANTE,
  payload: { handNo: hand.no, variante },
});
export const wiise = (
  hand: SchieberHand,
  playerId: number,
  teamId: number,
  betrag: number
) => ({
  type: AT.ADD_SCHIEBERWEIS,
  payload: { handNo: hand.no, playerId, teamId, betrag },
});
export const removeWiisTeam1 = (index: number, handNo: number) => ({
  type: AT.REMOVE_SCHIEBERWEIS_TEAM1,
  payload: { index, handNo },
});
export const removeWiisTeam2 = (index: number, handNo: number) => ({
  type: AT.REMOVE_SCHIEBERWEIS_TEAM2,
  payload: { index, handNo },
});
export const putSchieberDrafts = (drafts: SchieberSession[]) => ({
  type: AT.PUT_SCHIEBERDRAFTS,
  payload: drafts,
});
export const putAttachment = (guid: string) => ({
  type: AT.PUT_SESSIONATTACHMENT,
  payload: guid,
});

export const fetchSchiebers = () => ({ type: AT.FETCH_SCHIEBERS });
export const fetchSchieber = (schieberId: number) => ({
  type: AT.FETCH_SCHIEBER,
  payload: schieberId,
});
export const fetchSchieberDetails = (sessionId: number) => ({
  type: AT.FETCH_SCHIEBERDETAILS,
  payload: sessionId,
});
export const fetchSchieberDrafts = () => ({ type: AT.FETCH_DRAFTS });

const populateInternalSchieber = (schieber: SchieberSession) => ({
  ...schieber,
  hand:
    schieber?.hand?.map((s: SchieberHand, index: number) => ({
      ...s,
      no: index + 1,
    })) ?? [],
});
