import Resizer from "react-image-file-resizer";

export const imageSizeToPx = (size: "s" | "m" | "l" | "xl") =>
  size === "s" ? 64 : size === "m" ? 192 : size === "l" ? 256 : 1024;

export const resizeImage = (
  file: any,
  size: "s" | "m" | "l" | "xl" | "max",
  callback: (uri: any) => any
) => {
  size === "max"
    ? callback(file)
    : Resizer.imageFileResizer(
        file,
        imageSizeToPx(size),
        imageSizeToPx(size),
        "PNG",
        100,
        0,
        callback,
        "blob"
      );
};
