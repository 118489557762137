import React from "react";

interface Props {
  size?: any;
}
export const Chruetz = React.memo<Props>(function Chruetz({ size = "1em" }) {
  return (
    <svg
      viewBox="0 0 256 256"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <svg width="256" height="256" xmlns="http://www.w3.org/2000/svg">
        <g id="Club">
          <path
            stroke="#000000"
            id="svg_10"
            d="m91.49081,236.21583c13.00086,-13.57598 24.0344,-29.74567 28.69476,-48.10449c-13.93672,10.59566 -26.71877,27.63813 -46.15978,26.99248c-14.67675,0.84236 -32.1601,0.85662 -42.49134,-11.41845c-23.08738,-18.56551 -29.2893,-55.67195 -10.33096,-79.29914c12.97359,-17.05976 35.62831,-24.29358 56.41881,-21.73481c13.09965,-5.55831 -2.56215,-19.63247 -4.13718,-28.41622c-9.74964,-31.862 13.21883,-70.07989 46.99214,-73.75032c24.22414,-3.47774 47.90524,12.20397 59.75847,32.55548c6.44141,14.24032 3.0852,30.40208 1.58236,45.17543c-5.06075,7.42416 -18.02194,25.38513 0.47413,23.9704c18.28731,-0.28941 37.59893,5.07937 49.65776,19.62178c17.6382,19.5857 19.25612,51.95123 1.51027,71.96421c-9.387,12.18978 -23.47306,21.94598 -39.41593,21.40281c-15.60434,1.5753 -32.50152,-1.80997 -43.56678,-13.69979c-4.71934,-3.307 -16.28333,-15.80157 -16.27934,-13.06148c11.55851,24.08002 24.62865,49.08684 47.75093,64.00857c8.26139,6.64271 -12.58338,2.07577 -17.62954,3.42966c-30.57389,0.02702 -61.14765,0.05457 -91.72154,0.08172c6.29755,-6.5727 12.59549,-13.14512 18.89276,-19.71783l0,-0.00002z"
            fill="#000000"
          />
        </g>
      </svg>
    </svg>
  );
});
