import { call, put } from "redux-saga/effects";
import axios from "axios";
import {
  fetchFulfilled,
  fetchPending,
  fetchRejected,
} from "../api/apiActionCreator";
import store from "../global/Store";
import { currentSubDomain } from "./commonUtil";

export interface ApiAction {
  action: () => any;
  url: string;
}

export function baseUrl(): string {
  return process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api/v1/"
    : "/api/v1/";
}

export function createHeaders(): any {
  const userObject = store?.getState()?.user?.currentUser;
  if (userObject) {
    return {
      Authorization: "Bearer " + userObject.token,
      club: currentSubDomain() ?? "-",
    };
  } else {
    return {
      club: currentSubDomain() ?? "-",
    };
  }
}

export function* watchApiCall(
  apiCall: ApiAction,
  actionCreator: (param: any) => { type: string; payload: any | any[] },
  callBack?: (param: any) => any
): any {
  const requestId = `REQUEST_${new Date().getTime()}`;
  yield put(fetchPending(requestId, apiCall.url));
  try {
    const res = yield call(apiCall.action);
    //res = yield res.json();
    yield put(fetchFulfilled(requestId));
    yield put(actionCreator(res));
    if (callBack) {
      yield callBack(res);
    }
  } catch (error: any) {
    console.warn(error);
    yield put(fetchRejected(requestId));
  }
}

export function fetchGet(url: string, queryParams?: any): ApiAction {
  const fullUrl = baseUrl() + url;
  const headers = createHeaders();
  return {
    action: () =>
      axios
        .get(fullUrl, {
          timeout: 10000,
          headers,
          params: { ...queryParams },
        })
        .then((response) => response.data),
    url: fullUrl,
  };
}

export function fetchPost(url: string, data: any): ApiAction {
  const fullUrl = baseUrl() + url;
  const headers = createHeaders();
  return {
    action: () =>
      axios
        .post(fullUrl, data, {
          timeout: 10000,
          headers,
        })
        .then((response) => response.data),
    url: fullUrl,
  };
}

export function fetchPostFile(url: string, dataUrl: any): ApiAction {
  const fullUrl = baseUrl() + url;
  const headers = createHeaders();
  return {
    action: () =>
      fetch(dataUrl)
        .then((r) => r.blob())
        .then(
          (blobFile) => new File([blobFile], "Anhang", { type: "image/jpg" })
        )
        .then((file) =>
          axios.post(fullUrl, file, {
            timeout: 20000,
            headers,
          })
        )
        .then((response) => response.data),
    url: fullUrl,
  };
}
