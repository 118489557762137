import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { wiise } from "../../../games/gamesActionCreators";
import { SchieberHand } from "../../../games/gamesReducer";
import { selectSchieberHand } from "../../../games/gamesSelector";
import { RootState } from "../../../global/Store";
import { RegisterSchieberHandAsaege } from "./RegisterSchieberHandAsaege";
import { RegisterSchieberHandWiise } from "./RegisterSchieberHandWiise";
import { RegisterSchieberHandWiiser } from "./RegisterSchieberHandWiiser";

import "./erfassen.scss";

interface Props {
  no: number;
}
export function RegisterSchieberHand(props: Props) {
  const dispatch = useDispatch();

  const hand = useSelector((state: RootState) =>
    selectSchieberHand(state, props.no)
  );

  const addwiis = useCallback(
    (wiis: { playerId: number; amount: number; teamId: number }) => {
      if (hand) {
        dispatch(
          wiise(hand as SchieberHand, wiis.playerId, wiis.teamId, wiis.amount)
        );
      }
    },
    [dispatch, hand]
  );

  return hand ? (
    <>
      <RegisterSchieberHandAsaege
        key={"RegisterSchieberHandAsaege" + props.no}
        hand={hand}
      ></RegisterSchieberHandAsaege>
      <RegisterSchieberHandWiiser hand={hand} setWiis={addwiis} />
      <RegisterSchieberHandWiise hand={hand} />
    </>
  ) : (
    <div>Nüt gfonde</div>
  );
}
