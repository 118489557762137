import React from "react";
import { Route, Switch } from "react-router-dom";
import { Nav } from "./components/Navigation/Nav";
import { NavItemLink } from "./components/Navigation/NavItemLink";
import { NavTitle } from "./components/Navigation/NavTitle";
import { NavItems } from "./components/Navigation/NavItems";
import { Body } from "./components/Common/Body";
import { Main } from "./components/Common/Main";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./global/Store";
import "./App.scss";
import { PreviewZ } from "./components/preview/PreviewZ";
import ClubJoin from "./components/Portal/ClubJoin";
import ClubCreate from "./components/Portal/ClubCreate";
import ClubAbout from "./components/Portal/ClubAbout";
import Clubs from "./components/Portal/Clubs";
function PortalApp() {
  return (
    <>
      <Main>
        <ConnectedRouter history={history}>
          <Nav>
            <NavTitle>Jasserei</NavTitle>
            <NavItems hidden={false} click={() => {}}>
              <NavItemLink to="/clubs">Portal</NavItemLink>
              <NavItemLink to="/join">Mitmache</NavItemLink>
              <NavItemLink to="/about">Info</NavItemLink>
            </NavItems>
          </Nav>
          <Body click={() => {}}>
            <Switch>
              <Route path="/join">
                <ClubJoin />
              </Route>
              <Route path="/create">
                <ClubCreate />
              </Route>
              <Route path="/about">
                <ClubAbout />
              </Route>
              <Route path="/clubs">
                <Clubs />
              </Route>
              <Route path="/">
                <PreviewZ />
              </Route>
            </Switch>
          </Body>
        </ConnectedRouter>
      </Main>
    </>
  );
}
export default PortalApp;
