import React, { useCallback } from "react";
import {
  faSignInAlt,
  faSignOutAlt,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../user/userSelector";
import { NavItemLink } from "../Navigation/NavItemLink";
import {
  deleteAuthData,
  deletePersonalData,
} from "../../user/userActionCreators";
import { useHistory } from "react-router";

export function UserWidget() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const logout = useCallback(() => {
    dispatch(deleteAuthData);
    dispatch(deletePersonalData);
    history.push("login");
  }, [dispatch, history]);

  return user ? (
    user.nickname ? (
      <NavItemLink to="/user">
        <FontAwesomeIcon icon={faUserAlt} />
        &nbsp;
        <strong>{user.nickname}</strong>
      </NavItemLink>
    ) : (
      <FontAwesomeIcon icon={faSignOutAlt} onClick={logout} />
    )
  ) : (
    <NavItemLink to="/login">
      <FontAwesomeIcon icon={faSignInAlt} />
    </NavItemLink>
  );
}
