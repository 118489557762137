import React from "react";
import { faChevronRight, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import {
  currentStandingWidget,
  selectEditSchieber,
} from "../../../games/gamesSelector";
import { RegisterSchieberNavigatorTile } from "./Navigator/RegisterSchieberNavigatorTile";
import { useHistory } from "react-router-dom";

export function CurrentRegisterWidget() {
  const history = useHistory();

  const currentStanding = useSelector(currentStandingWidget);
  const schieber = useSelector(selectEditSchieber);

  const goToErfassung = () => history.push("/erfassen");
  return (
    <div className="nav-small">
      <RegisterSchieberNavigatorTile
        perRow={1}
        action={goToErfassung}
        key={"back"}
      >
        {schieber.sessionId ? currentStanding : "Erfassig"}
        &nbsp; &nbsp;
        <FontAwesomeIcon icon={faEdit} />
        &nbsp; &nbsp;
        <FontAwesomeIcon icon={faChevronRight} />
        &nbsp; &nbsp;
        <FontAwesomeIcon icon={faChevronRight} />
      </RegisterSchieberNavigatorTile>
    </div>
  );
}
