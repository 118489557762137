import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../global/Store";

export function ReduxVisualizer() {
  const state = useSelector((state: RootState) => state);

  const json = useMemo(() => JSON.stringify(state, undefined, 2), [state]);

  return (
    <>
      <div>
        <code>
          {" "}
          <pre>{json}</pre>
        </code>
      </div>
    </>
  );
}
