import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../global/Store";
import { selectPlayer } from "../../../players/playerSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { SchieberHand } from "../../../games/gamesReducer";

import "./erfassen.scss";

interface Props {
  hand: SchieberHand;
}
export function RegisterSchieberHandAsaeger(props: Props) {
  const dra = useSelector((state: RootState) =>
    selectPlayer(state, props.hand.playerDragsiId)
  );
  const gschobe = useSelector((state: RootState) =>
    selectPlayer(state, props.hand?.playerGschobeId)
  );

  return (
    <div className="asaeger">
      <div className={gschobe ? "hetgschobeplayer" : "asaegerplayer"}>
        {dra?.nickname}
      </div>
      <div className={gschobe ? "gschobneplayer" : "partnerplayer"}>
        <FontAwesomeIcon icon={faArrowCircleRight} />
        <span>&nbsp;&nbsp;{gschobe?.nickname}</span>
      </div>
    </div>
  );
}
