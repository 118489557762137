import React from "react";
import { useSelector } from "react-redux";
import { SchieberSession } from "../../../games/gamesReducer";
import { RootState } from "../../../global/Store";
import { selectTeamTitle } from "../../../players/playerSelector";

interface Props {
  session: SchieberSession;
}
export function SchieberTimelineTeams(props: Props) {
  const team1 = useSelector((state: RootState) =>
    selectTeamTitle(state, props.session.team1)
  );
  const team2 = useSelector((state: RootState) =>
    selectTeamTitle(state, props.session.team2)
  );

  return (
    <tr className="timeline-team-header">
      <td colSpan={3}>
        <table className="max timeline-headertable">
          <tbody>
            <tr>
              <td className={"rechts halb timeline-team"}>{team1}</td>
              <td className={"timeline-vs"}>&nbsp;vs.&nbsp;</td>
              <td className={"links halb timeline-team"}>{team2}</td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}
