import React, { useCallback } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { putCurrentSchieber } from "../../../games/gamesActionCreators";
import { initialSchieberCreator } from "../../../games/gamesReducer";
import { deleteSession } from "../../../games/registerGameActionCreators";
import { PromptedButton } from "../../Common/PromptedButton";
import { selectUser } from "../../../user/userSelector";

export function CancelSchieberRegister(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(selectUser);

  const reset = useCallback(() => {
    dispatch(deleteSession());
    dispatch(
      putCurrentSchieber(initialSchieberCreator(user?.defaultKartendeck))
    );
    history.push("/drafts");
  }, [dispatch, user?.defaultKartendeck, history]);

  return (
    <PromptedButton
      color="secondary"
      okFn={reset}
      startIcon={<FontAwesomeIcon icon={faTrash} />}
      promptText={"Wotsch de Entwurf lösche ond e neui Erfassig afange?"}
      needsPrompt={true}
    >
      {props.children}
    </PromptedButton>
  );
}
