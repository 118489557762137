import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectApiStatus } from "../../api/apiSelector";
import { ApiStatus } from "../../types/types";
import { putAuthExpired } from "../../user/userActionCreators";
import { selectUser } from "../../user/userSelector";

export function AuthWidget() {
  const dispatch = useDispatch();
  const apiStatus = useSelector(selectApiStatus);
  const user = useSelector(selectUser);
  useEffect(() => {
    if (
      apiStatus === ApiStatus.failed &&
      user?.expiration &&
      new Date(user.expiration).valueOf() < Date.now()
    ) {
      dispatch(putAuthExpired);
    }
  }, [apiStatus, dispatch, user?.expiration]);

  return apiStatus === ApiStatus.authExpired ? (
    <div className={"global-header-warn"}>
      Wer besch du überhaupt? Bitte neu ilogge.
    </div>
  ) : (
    <></>
  );
}
