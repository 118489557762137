import { useDispatch, useSelector } from "react-redux";
import {
  selectEditSchieber,
  selectInitSchieberValid,
} from "../../../games/gamesSelector";
import { lockInput } from "../../../games/registerGameActionCreators";
import { selectPlayerTitle } from "../../../players/playerSelector";
import { RootState } from "../../../global/Store";
import { RegisterSchieberNavigatorTile } from "./Navigator/RegisterSchieberNavigatorTile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useCallback } from "react";

export function LockSchieberRegister() {
  const dispatch = useDispatch();

  const schieber = useSelector(selectEditSchieber);
  const initSchieberValid = useSelector(selectInitSchieberValid);

  const startPlayerName = useSelector((state: RootState) =>
    selectPlayerTitle(state, schieber?.team1?.player1Id)
  );

  const start = useCallback(() => {
    dispatch(lockInput());
  }, [dispatch]);

  return (
    <>
      {!schieber?.sessionId && (
        <div className="nav-small">
          <RegisterSchieberNavigatorTile
            perRow={initSchieberValid ? 2 : 1}
            disabled={!initSchieberValid}
            action={start}
            key={"back"}
          >
            {initSchieberValid
              ? schieber?.team1?.player1Id && startPlayerName + " fangt ah."
              : "Spieler uswähle"}
          </RegisterSchieberNavigatorTile>
          {initSchieberValid && (
            <RegisterSchieberNavigatorTile
              perRow={2}
              disabled={!initSchieberValid}
              empathize={initSchieberValid}
              action={start}
              key={"forward"}
            >
              Guet Jass! <FontAwesomeIcon icon={faCheckCircle} />
            </RegisterSchieberNavigatorTile>
          )}
        </div>
      )}
    </>
  );
}
