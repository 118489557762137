import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { fetchLogin } from "../../user/userActionCreators";
import { selectUser } from "../../user/userSelector";

export function LoginPage() {
  const location = useLocation();

  const user = useSelector(selectUser);

  const from = (location?.state as any)?.from ?? "/home";

  return user ? (
    <Redirect
      to={{
        pathname: from,
      }}
    />
  ) : (
    <LoginPageInternal></LoginPageInternal>
  );
}

function LoginPageInternal() {
  const dispatch = useDispatch();

  const [state, setState] = useState<{
    nickname: string;
    password: string;
    submitted: boolean;
  }>({
    nickname: "",
    password: "",
    submitted: false,
  });

  const handleChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setState({ ...state, [name]: value });
    },
    [state]
  );
  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      setState({ ...state, submitted: true });
      if (state.nickname && state.password) {
        dispatch(
          fetchLogin({ nickname: state.nickname, password: state.password })
        );
      }
    },
    [dispatch, state]
  );

  return (
    <form onSubmit={handleSubmit}>
      <h2>Login</h2>
      <div
        className={
          "form-group" +
          (state.submitted && !state.nickname ? " has-error" : "")
        }
      >
        <label htmlFor="nickname">Spitzname</label>
        <input
          type="text"
          className="form-control"
          name="nickname"
          value={state.nickname}
          onChange={handleChange}
        />
        {state.submitted && !state.nickname && (
          <div className="help-block">Spitzname esch Pflicht</div>
        )}
      </div>
      <div
        className={
          "form-group" +
          (state.submitted && !state.password ? " has-error" : "")
        }
      >
        <label htmlFor="password">Kennwort</label>
        <input
          type="password"
          className="form-control"
          name="password"
          value={state.password}
          onChange={handleChange}
        />
        {state.submitted && !state.password && (
          <div className="help-block">Kennwort esch Pflicht</div>
        )}
      </div>
      <div className="form-group">
        <button className="btn btn-primary" type="submit">
          Login
        </button>
      </div>
    </form>
  );
}
