import React, { useMemo } from "react";
import "../../App.scss";

interface Props {
  hidden: boolean;
  children: any;
  click: () => void;
}
export function NavItems(props: Props) {
  const classes = useMemo(
    () =>
      "navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse show" +
      (props.hidden ? " d-none d-sm-block" : ""),
    [props.hidden]
  );

  return (
    <>
      <div className={classes} onClick={props.click}>
        <ul className="j-navbar-itemlist navbar-nav flex-grow">
          {props.children}
        </ul>
      </div>
    </>
  );
}
