import { AT } from "../global/actionTypes";

export const fetchPending = (id: string, url: string) => ({
  type: AT.FETCH_PENDING,
  payload: { id, url },
});
export const fetchFulfilled = (id: string) => ({
  type: AT.FETCH_FULFILLED,
  payload: { id },
});
export const fetchRejected = (id: string) => ({
  type: AT.FETCH_REJECTED,
  payload: { id },
});

export const putResponseMessage = (response: any) => ({
  type: AT.FETCH_RESPONSE,
  payload: {
    message: Boolean(response) ? "success" : "warning",
    severity: Boolean(response) ? "success" : "warning",
  },
});

export const createResponseMessage =
  (successMsg: string, failMsg: string) => (response: any) => ({
    type: AT.FETCH_RESPONSE,
    payload: {
      message: response ? successMsg : failMsg,
      severity: Boolean(response) ? "success" : "warning",
    },
  });
