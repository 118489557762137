import { useMemo } from "react";
import { SchieberHand } from "../../../games/gamesReducer";
import { varianteToFactor, weisReducerFn } from "../../../games/gamesSelector";

interface Props {
  hand: SchieberHand;
}
export function SchieberTimelineWeis2(props: Props) {
  const team1Wiis = useMemo(
    () =>
      (props.hand?.team1Wiis?.reduce(weisReducerFn, 0) ?? 0) *
      varianteToFactor(props.hand.variante),
    [props.hand?.team1Wiis, props.hand.variante]
  );
  const team2Wiis = useMemo(
    () =>
      (props.hand?.team2Wiis?.reduce(weisReducerFn, 0) ?? 0) *
      varianteToFactor(props.hand.variante),
    [props.hand?.team2Wiis, props.hand.variante]
  );
  return (
    <>
      {team1Wiis + team2Wiis > 0 && (
        <tr className="timeline-wiis">
          <td
            colSpan={2}
            style={{
              textAlign: "start",
            }}
          ></td>
          <td
            style={{
              textAlign: "start",
            }}
          >
            <div>
              {props.hand.team1Wiis.map((weis, index) => (
                <span key={index}>{weis.betrag}&nbsp;</span>
              ))}
              {team1Wiis > 0 && team2Wiis > 0 && <>&nbsp;|&nbsp;&nbsp;</>}
              {props.hand.team2Wiis.map((weis, index) => (
                <span key={index}>{weis.betrag}&nbsp;</span>
              ))}
            </div>
          </td>
          <td
            style={{
              borderLeft: "2px solid",
              borderRight: "2px solid",
              textAlign: "right",
            }}
          >
            {team1Wiis}
          </td>
          <td
            style={{
              borderLeft: "2px solid",
              borderRight: "2px solid",
              textAlign: "right",
            }}
          >
            {team2Wiis}
          </td>
        </tr>
      )}
    </>
  );
}
