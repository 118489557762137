import { AT } from "../global/actionTypes";
import { User } from "../types/types";

export const putUsers = (users: User[]) => ({
  type: AT.PUT_USERS,
  payload: users,
});
export const putUserDetails = (user: User) => ({
  type: AT.PUT_USERDETAILS,
  payload: user,
});

export const updateUser = (user: User) => ({
  type: AT.FETCH_USER_UPDATE,
  payload: user,
});
export const createUser = (nickname: string) => ({
  type: AT.FETCH_USER_CREATE,
  payload: { nickname },
});
export const announceNewUser = (user: User) => ({
  type: AT.ANNOUNCE_NEWUSER,
  payload: { ...user },
});

export const fetchUsers = () => ({ type: AT.FETCH_USERS });
