import React, { useCallback } from "react";
import { MenuItem, TextField } from "@material-ui/core";
import { useJassereiFormStyles } from "../../Common/useJassereiFormStyles";
import { Season } from "../../../seasons/seasonReducer";
import { SchieberSession } from "../../../games/gamesReducer";

interface Props {
  schieber: SchieberSession;
  setter: (part: any) => any;
  seasons: Season[];
}
export function RegisterSchieberForm(props: Props) {
  const styles = useJassereiFormStyles();

  const handleChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      props.setter({ [name]: value });
    },
    [props]
  );

  return (
    <>
      <form noValidate autoComplete="off" className={styles.root}>
        <div className="container row">
          <TextField
            select
            name="seasonId"
            key="seasonId"
            label="Saison"
            className={styles.registerTextfield}
            variant="outlined"
            placeholder="Saison"
            disabled={Boolean(props.schieber.sessionId)}
            value={props.schieber.seasonId}
            onChange={handleChange}
          >
            {props.seasons?.map((season: Season) => (
              <MenuItem value={season.seasonId} key={season.seasonId}>
                {season.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="remarks"
            name="remarks"
            key="remarks"
            label="Bemerkige"
            variant="outlined"
            disabled={Boolean(props.schieber.sessionId)}
            onChange={handleChange}
            fullWidth
            value={props.schieber.remarks}
          />
        </div>
      </form>
    </>
  );
}
