import React, { useCallback } from "react";

import "../erfassen.scss";

const availableElementStyle = "nav-element";
const disabledElementStyle = "nav-element nav-element-disabled";
const currentElementStyle = "nav-element nav-element-current";
const actionElementStyle = "nav-element nav-element-empathize";
const cancelElementStyle = "nav-element nav-element-cancel";
const swipeElementStyle = "nav-element nav-element-swipethize";
interface Props {
  perRow: number;
  action: () => any;
  disabled?: boolean;
  current?: boolean;
  children: any;
  empathize?: boolean;
  swipethize?: boolean;
  cancel?: boolean;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export function RegisterSchieberNavigatorTile(props: Props) {
  const action = useCallback(() => !props.disabled && props.action(), [props]);

  return (
    <div
      className={
        props.cancel
          ? cancelElementStyle
          : props.disabled
          ? disabledElementStyle
          : props.current
          ? currentElementStyle
          : props.empathize
          ? actionElementStyle
          : props.swipethize
          ? swipeElementStyle
          : availableElementStyle
      }
      style={{ width: `${100 / (props.perRow > 12 ? 12 : props.perRow)}%` }}
      onClick={action}
    >
      {props.children}
    </div>
  );
}
