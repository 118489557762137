import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faTimes } from "@fortawesome/free-solid-svg-icons";
import { selectPlayer } from "../../../players/playerSelector";
import { RootState } from "../../../global/Store";

import "./erfassen.scss";
interface Props {
  betrag: number;
  playerId: number;
  index: number;
  deleteAction: (index: number) => any;
}
export function RegisterSchieberHandWiisListItem(props: Props) {
  const dispatch = useDispatch();

  const player = useSelector((state: RootState) =>
    selectPlayer(state, props.playerId)
  );

  const removeWiis = useCallback(
    (index: number) => {
      dispatch(props.deleteAction(index));
    },
    [dispatch, props]
  );

  return (
    <div className="wiis">
      <div>
        <FontAwesomeIcon icon={faDollarSign} />
      </div>
      <div>{props.betrag}</div>
      <div>{player?.nickname}</div>
      <div className="wiis-delete" onClick={() => removeWiis(props.index)}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </div>
  );
}
