import React, { useMemo, useState } from "react";
import { Switch } from "@material-ui/core";
import { useSelector } from "react-redux";
import { SchieberHand, SchieberSession } from "../../games/gamesReducer";
import {
  team1sumreducerFn,
  team2sumreducerFn,
} from "../../games/gamesSelector";
import { RootState } from "../../global/Store";
import { selectPlayers, selectTeamTitle } from "../../players/playerSelector";
import { Player } from "../../types/types";
import Chart from "react-google-charts";

interface Props {
  schieber: SchieberSession;
}
export function SchieberLineGraph2(props: Props) {
  const players = useSelector(selectPlayers);
  const team1 = useSelector((state: RootState) =>
    selectTeamTitle(state, props.schieber.team1)
  );
  const team2 = useSelector((state: RootState) =>
    selectTeamTitle(state, props.schieber.team2)
  );
  const [cummulative, setCummulative] = useState(true);

  const data = useMemo(() => {
    let header = ["Spieler", { role: "annotation" }, team1, team2];
    let data = props?.schieber?.hand?.map((hand: SchieberHand, i: number) => [
      players?.find((p: Player) => p.userId === hand.playerDragsiId)
        ?.displayName ?? "",
      "",
      cummulative
        ? props?.schieber?.hand
            ?.slice(0, i + 1)
            ?.reduce(
              (prev: number, current: SchieberHand) =>
                prev + team1sumreducerFn(0, current),
              0
            )
        : team1sumreducerFn(0, hand),
      cummulative
        ? props?.schieber?.hand
            ?.slice(0, i + 1)
            ?.reduce(
              (prev: number, current: SchieberHand) =>
                prev + team2sumreducerFn(0, current),
              0
            )
        : team2sumreducerFn(0, hand),
    ]);
    data = cummulative
      ? data?.map((v) => [...v, props?.schieber?.pointsGoal])
      : data;
    header = cummulative ? [...(header ?? []), "Ziel"] : header;
    return [header, ...(data ?? [])];
  }, [
    cummulative,
    players,
    props?.schieber?.hand,
    props?.schieber?.pointsGoal,
    team1,
    team2,
  ]);

  return (
    props.schieber?.hand && (
      <>
        <Chart
          chartType="LineChart"
          loader={<div>En Momänt Geduld...</div>}
          data={data}
          options={{
            annotations: {
              style: "line",
            },
            chartArea: { left: 50, top: 10, width: "100%", height: "85%" },
            height: 400,
            vAxis: { minValue: 0 },
            // For the legend to fit, we make the chart area smaller
            legend: { position: "bottom" },
          }}
        />
        <br />
        <Switch
          size="small"
          checked={cummulative}
          onChange={() => setCummulative(!cummulative)}
        />
      </>
    )
  );
}
