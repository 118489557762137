import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { fetchSeasons } from "../../seasons/seasonActionCreators";
import { Season } from "../../seasons/seasonReducer";
import { selectSeasons } from "../../seasons/seasonSelector";
import { useJassereiFormStyles } from "../Common/useJassereiFormStyles";

interface Props {
  seasons: Season[];
}

export function ManageSeasons() {
  const dispatch = useDispatch();

  const seasons = useSelector(selectSeasons);

  useEffect(() => {
    dispatch(fetchSeasons());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return seasons ? (
    <ManageSeasonsInternal seasons={seasons} />
  ) : (
    <div>Nix gfonde</div>
  );
}

function ManageSeasonsInternal(props: Props) {
  const classes = useJassereiFormStyles();
  const history = useHistory();

  const gotoDetails = useCallback(
    (season: Season) => history.push(`/admin/season/${season.seasonId}`),
    [history]
  );

  return (
    <table
      className={"table table-striped " + classes.overViewTable}
      aria-labelledby="tableLabel"
    >
      <thead>
        <tr>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.seasons.map((season: Season) => (
          <tr key={season.seasonId} onClick={() => gotoDetails(season)}>
            <td>{season.name}</td>
            <td>{season.active ? "aktiv" : "inaktiv"}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
