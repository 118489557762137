import { all, put, takeEvery, takeLatest } from "redux-saga/effects";
import { announceNewSeason, putSeasons } from "./seasonActionCreators";
import { AT } from "../global/actionTypes";
import { createSeason, loadSeasons, updateSeason } from "../api/seasonsApi";
import { watchApiCall } from "../util/apiUtil";
import { createResponseMessage } from "../api/apiActionCreator";

export function* fetchSeasonsSaga() {
  yield watchApiCall(loadSeasons(), putSeasons);
}
export function* watchFetchSeasonsSaga() {
  yield takeLatest(AT.FETCH_SEASONS, fetchSeasonsSaga);
}

export function* fetchCreateSeasonSaga(seasonName: string) {
  yield watchApiCall(createSeason(seasonName), announceNewSeason);
}
export function* watchFetchCreateSeasonSaga() {
  yield takeLatest(AT.FETCH_SEASON_ADD, (action: any) =>
    fetchCreateSeasonSaga(action.payload)
  );
}
export function* fetchUpdateSeasonSaga(season: any) {
  yield watchApiCall(
    updateSeason(season),
    createResponseMessage("Änderige send gspeicheret.", "Ned chöne speichere.")
  );
}
export function* watchFetchUpdateSeasonSaga() {
  yield takeEvery(AT.FETCH_SEASON_UPDATE, (action: any) =>
    fetchUpdateSeasonSaga(action.payload)
  );
}

export function* addedSeason(seasonName: string) {
  yield fetchSeasonsSaga();
  yield put(
    createResponseMessage("Saison erstellt", "Ned chöne erstelle.")(seasonName)
  );
}
export function* watchAddedSeason() {
  yield takeLatest(AT.ANNOUNCE_NEWSEASON, (action: any) =>
    addedSeason(action.payload)
  );
}

export default function* rootSeasonSaga() {
  yield all([
    watchFetchSeasonsSaga(),
    watchFetchCreateSeasonSaga(),
    watchFetchUpdateSeasonSaga(),
    watchAddedSeason(),
  ]);
}
