import React, { useMemo } from "react";

interface Props {
  label: string;
  value?: number;
  text?: string;
  type?: "percent" | "text" | "value";
}
export function ProfileEntry(props: Props) {
  const unit = props.type === "percent" ? "%" : "";
  const value = useMemo(
    () =>
      props.value &&
      Math.round(
        (props.type === "percent" ? props.value * 100 : props.value) * 100
      ) / 100,
    [props.type, props.value]
  );

  return (
    <div>
      {props.label}: {value}
      {unit}
      {props.text}
    </div>
  );
}
