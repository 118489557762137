import React, { useMemo } from "react";
import moment from "moment";
import { SchieberDraft } from "../../games/gamesReducer";
import { SchieberDraftApproval } from "./SchieberDraftApproval";
import { PlayerWidget } from "../Common/PlayerWidget";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  draft: SchieberDraft;
  playerId1?: number;
  playerId2?: number;
  playerId3?: number;
  playerId4?: number;
}
export function SchieberDraftRow(props: Props) {
  const formattedDate = useMemo(
    // eslint-disable-next-line no-restricted-syntax
    () => moment(props.draft.createdOn).format("D.M. HH:mm"),
    [props.draft.createdOn]
  );

  return (
    <>
      <tr className="timeline-draft">
        <td>
          <FontAwesomeIcon icon={faSave} />
          &nbsp;&nbsp;&nbsp;
        </td>
        <td colSpan={2}>
          <PlayerWidget
            className={"timeline-label"}
            playerId={props.draft.creatorId}
          />
          <span className={"timeline-time-indication"}>{formattedDate}</span>
        </td>
      </tr>
      <SchieberDraftApproval
        playerId={props.playerId1}
        date={props.draft.player1Approval}
      />
      <SchieberDraftApproval
        playerId={props.playerId2}
        date={props.draft.player2Approval}
      />
      <SchieberDraftApproval
        playerId={props.playerId3}
        date={props.draft.player3Approval}
      />
      <SchieberDraftApproval
        playerId={props.playerId4}
        date={props.draft.player4Approval}
      />
    </>
  );
}
