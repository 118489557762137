import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClubs } from "../../clubs/clubsActionCreators";
import { selectClubs } from "../../clubs/clubsSelector";
import { getSubDomainUrl } from "../../util/commonUtil";
import { Image } from "../Common/Image";

function Clubs() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchClubs());
  }, [dispatch]);
  const clubs = useSelector(selectClubs);

  const navigate = (shortName: string) => {
    window.location.href = getSubDomainUrl(shortName);
  };

  return (
    <>
      <h2>Registrierti Verein</h2>
      <table className="table table-striped">
        <tbody>
          {clubs?.map((c, i) => (
            <tr key={c.clubId + i} onClick={() => navigate(c.shortName)}>
              <td>
                <Image size="s" guid={c?.webIcon} />
              </td>
              <td className="d-none d-sm-table-cell">
                <p className="lead">{c.shortName}</p>
              </td>
              <td className="d-sm-none d-table-cell">{c.clubName}</td>
              <td className="d-none d-sm-table-cell">{c.longName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
export default Clubs;
