import React from "react";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SchieberHand } from "../../../games/gamesReducer";
import {
  team1sumreducerFn,
  team2sumreducerFn,
} from "../../../games/gamesSelector";
import { VarianteIcon } from "../../Assets/VarianteIcon";
import { SchieberTimelineWeis } from "./SchieberTimelineWeis";
import { PlayerWidget } from "../../Common/PlayerWidget";

import "../timeline.scss";

interface Props {
  hand: SchieberHand;
}
export function SchieberTimelineHand(props: Props) {
  return (
    <>
      <tr className="timeline-hand-line">
        <td>{team1sumreducerFn(0, props.hand)}</td>
        <td>
          <div>
            <span className="links">{props.hand.team1Result} </span>
            {props.hand?.playerGschobeId ? (
              <>
                <FontAwesomeIcon icon={faArrowCircleRight} />{" "}
                <PlayerWidget
                  playerId={props.hand?.playerGschobeId}
                  className={"timeline-label mitte timeline-gschobne"}
                />{" "}
                <VarianteIcon variante={props.hand.variante}></VarianteIcon>
              </>
            ) : (
              <>
                <PlayerWidget
                  playerId={props.hand?.playerDragsiId}
                  className={"timeline-label mitte"}
                />{" "}
                <VarianteIcon variante={props.hand.variante}></VarianteIcon>
              </>
            )}
            <span className="rechts">{props.hand.team2Result}</span>
          </div>
        </td>
        <td>{team2sumreducerFn(0, props.hand)}</td>
      </tr>
      <SchieberTimelineWeis hand={props.hand}></SchieberTimelineWeis>
    </>
  );
}
