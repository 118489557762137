import { useCallback, useMemo, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useJassereiFormStyles } from "../Common/useJassereiFormStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { fetchCreateClub } from "../../clubdetails/clubdetailsActionCreators";
import { getSubDomainUrl } from "../../util/commonUtil";

function ClubCreate() {
  const [club, setClub] = useState({
    shortName: "",
    longName: "",
    clubId: 0,
    clubName: "",
    contactAdress: "",
    contactEmail: "",
    contactLastName: "",
    contactName: "",
    userName: "",
    password: "",
  });

  const styles = useJassereiFormStyles();
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setClub({
        ...club,
        [name]: value,
      });
    },
    [club]
  );

  const handleClubNameChange = useCallback(
    (e: any) =>
      setClub({
        ...club,
        clubName: e.target.value,
        longName:
          club.longName === e.target.value.substring(0, club.longName.length)
            ? e.target.value
            : club.longName,
      }),
    [club]
  );

  const save = useCallback(
    (e: any) => {
      dispatch(fetchCreateClub(club));
    },
    [dispatch, club]
  );

  const clubNameValid = useMemo(() => club.clubName?.length > 4, [club]);
  const longNameValid = useMemo(() => club.longName?.length > 4, [club]);
  const sonderzeiche = useMemo(
    () => (club.shortName.match(/[^a-zA-Z0-9]/) ? true : false),
    [club]
  );
  const shortNameValid = useMemo(
    () =>
      club.shortName?.length > 2 &&
      club.shortName?.length < 15 &&
      !sonderzeiche,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [club]
  );
  const userNameValid = useMemo(() => club.userName?.length > 3, [club]);
  const passwordValid = useMemo(() => club.password?.length > 3, [club]);

  const valid = useMemo(
    () =>
      clubNameValid &&
      longNameValid &&
      shortNameValid &&
      userNameValid &&
      passwordValid,
    [clubNameValid, longNameValid, passwordValid, shortNameValid, userNameValid]
  );

  const urlPreview = useMemo(
    () =>
      sonderzeiche
        ? "Kei Sonderzeiche!"
        : getSubDomainUrl(club.shortName?.length > 0 ? club.shortName : "???"),
    [club.shortName, sonderzeiche]
  );

  return (
    <>
      <h2>Neue Verein</h2>
      <form noValidate autoComplete="off" className={styles.root}>
        <TextField
          id="clubName"
          name="clubName"
          label="Vereinsname"
          helperText="Wird azeigt ide Übersicht"
          error={!clubNameValid}
          variant="outlined"
          onChange={handleClubNameChange}
          value={club?.clubName}
          inputProps={{ maxLength: 255, minLength: 3 }}
        />
        <TextField
          id="longName"
          name="longName"
          label="Vereinsname lang"
          error={!longNameValid}
          variant="outlined"
          onChange={handleChange}
          value={club?.longName}
          inputProps={{ maxLength: 255, minLength: 3 }}
        />
        <TextField
          id="shortName"
          name="shortName"
          label="Kurzname"
          helperText={urlPreview}
          error={!shortNameValid}
          variant="outlined"
          onChange={handleChange}
          value={club?.shortName}
          inputProps={{ maxLength: 15, minLength: 3 }}
        />
        <hr />
        <h2>Administratorzuegang</h2>
        <TextField
          id="userName"
          name="userName"
          label="Benutzername"
          helperText={userNameValid ? "" : "Mindestens 4 Zeiche"}
          error={!userNameValid}
          variant="outlined"
          onChange={handleChange}
          value={club?.userName}
          inputProps={{ maxLength: 24 }}
        />
        <TextField
          id="password"
          name="password"
          label="Passwort"
          helperText={passwordValid ? "" : "Mindestens 4 Zeiche"}
          error={!passwordValid}
          variant="outlined"
          type="password"
          onChange={handleChange}
          value={club?.password}
          inputProps={{ maxLength: 24 }}
        />
        <hr />
        <h2>Kontaktdate</h2>
        <TextField
          id="contactAdress"
          name="contactAdress"
          label="Adresse"
          variant="outlined"
          onChange={handleChange}
          value={club?.contactAdress}
          inputProps={{ maxLength: 128 }}
        />
        <TextField
          id="contactEmail"
          name="contactEmail"
          label="Email"
          variant="outlined"
          type="email"
          onChange={handleChange}
          value={club?.contactEmail}
          inputProps={{ maxLength: 64 }}
        />
        <TextField
          id="contactLastName"
          name="contactLastName"
          label="Nachname"
          variant="outlined"
          onChange={handleChange}
          value={club?.contactLastName}
          inputProps={{ maxLength: 64 }}
        />
        <TextField
          id="contactName"
          name="contactName"
          label="Vorname"
          variant="outlined"
          onChange={handleChange}
          value={club?.contactName}
          inputProps={{ maxLength: 64 }}
        />
        <hr />
        <Button
          className={styles.root}
          variant="contained"
          color="primary"
          onClick={save}
          disabled={!valid}
          startIcon={<FontAwesomeIcon icon={faSave} />}
        >
          Erstelle
        </Button>
      </form>
    </>
  );
}

export default ClubCreate;
