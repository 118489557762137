import React from "react";

interface Props {
  toggler: () => void;
}
export function NavToggler(props: Props) {
  return (
    <button
      aria-label="Toggle navigation"
      className="navbar-toggler"
      data-target=".navbar-collapse"
      data-toggle="collapse"
      type="button"
      aria-expanded="false"
      onClick={props.toggler}
    >
      <span className="navbar-toggler-icon"></span>
    </button>
  );
}
