import React from "react";
import { useSelector } from "react-redux";
import {
  selectEditSchieber,
  selectTeam1Title,
  selectTeam2Title,
  team1totalpoints,
  team2totalpoints,
} from "../../../games/gamesSelector";

import "./erfassen.scss";

export function RegisterSchieberStanding() {
  const team1points = useSelector(team1totalpoints);
  const team2points = useSelector(team2totalpoints);
  const team1name = useSelector(selectTeam1Title);
  const team2name = useSelector(selectTeam2Title);
  const schieber = useSelector(selectEditSchieber);

  return (
    <>
      <div className="standing">
        <div style={{ width: "50%" }} key="team1name">
          {team1name}
        </div>
        <div style={{ width: "50%" }} key="team2name">
          {team2name}
        </div>
      </div>
      <div className="standing">
        <div style={{ width: "40%" }} key="team1">
          {team1points}
        </div>
        <div className="standing-goal" style={{ width: "20%" }} key="goal">
          {schieber?.pointsGoal ? schieber?.pointsGoal : "?"}
        </div>
        <div style={{ width: "40%" }} key="team2">
          {team2points}
        </div>
      </div>
    </>
  );
}
