import React from "react";
import { useSelector } from "react-redux";
import { selectClubdetails } from "../../clubdetails/clubdetailsSelector";
import { Image } from "../Common/Image";

import "./Welcome.scss";

export function Welcome() {
  const club = useSelector(selectClubdetails);
  return (
    <>
      <h2 className="center">Jassclub {club?.longName}</h2>
      <div className="container">
        <div className="row">
          <div className="col-sm-0 col-md-0 col-lg-2 col-xl-3"></div>
          <div className="col-sm-12 col-md-12 col-lg-8 col-xl-6">
            <Image size="max" guid={club?.mainImage} />
          </div>
          <div className="col-sm-0 col-md-0 col-lg-2 col-xl-3"></div>
        </div>
      </div>
    </>
  );
}
