import React from "react";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { fetchSchieberDrafts } from "../../../games/gamesActionCreators";
import { useJassereiFormStyles } from "../../Common/useJassereiFormStyles";
import { PromptedButton } from "../../Common/PromptedButton";

export function SchieberDraftsLoader(props: any) {
  const classes = useJassereiFormStyles();
  const dispatch = useDispatch();

  const reload = () => dispatch(fetchSchieberDrafts());

  return (
    <PromptedButton
      color="default"
      className={classes.root}
      okFn={reload}
      startIcon={<FontAwesomeIcon icon={faSync} />}
      needsPrompt={false}
    >
      {props.children}
    </PromptedButton>
  );
}
