import React, { useCallback, useEffect, useRef } from "react";
import { RegisterSchieberMatch } from "./RegisterSchieberMatch";

import "./erfassen.scss";

interface Props {
  onInput: (asaeger: number, gegner: number) => any;
  amount: number;
  isMatch?: boolean;
  isKonterMatch?: boolean;
  focus?: boolean;
  setFocus?: (v: boolean) => any;
}
export function RegisterSchieberHandAsaegeAmount(props: Props) {
  const inputEl = useRef(null as any);

  useEffect(() => {
    if (props.focus) {
      inputEl?.current?.select();
    }
  }, [props.focus]);

  const onPointsChanged = useCallback(
    (e: any) => {
      const val = Number(e.target.value);
      if (val >= 0 && val <= 157) {
        props.onInput(val, 157 - val);
      }
    },
    [props]
  );
  const onDone = useCallback(
    (e) => {
      onPointsChanged(e);
      props?.setFocus && props?.setFocus(false);
    },
    [onPointsChanged, props]
  );
  const onClick = (e: any) => e.target.select();

  return (
    <>
      <RegisterSchieberMatch {...props} />
      <div className="puenkt">
        <input
          type="number"
          ref={inputEl}
          value={props.amount}
          onChange={onPointsChanged}
          onBlur={onDone}
          onClick={onClick}
          max={157}
          min={0}
          step={1}
        ></input>
      </div>
    </>
  );
}
