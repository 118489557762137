import React from "react";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as C1 } from "./cards/ch-de/ch-de-1.svg";
import { ReactComponent as C2 } from "./cards/ch-de/ch-de-2.svg";
import { ReactComponent as C3 } from "./cards/ch-de/ch-de-3.svg";
import { ReactComponent as C4 } from "./cards/ch-de/ch-de-4.svg";
import { ReactComponent as D4 } from "./cards/de-de/Bay_gras.svg";
import { Chruetz } from "./Chruetz";
import { Egge } from "./Egge";
import { Herz } from "./Herz";
import { Schufle } from "./Schufle";
import { Farb } from "./Farb";

const mapping = [
  { v: 1, c: <Herz /> },
  { v: 2, c: <Egge /> },
  { v: 3, c: <Chruetz /> },
  { v: 4, c: <Schufle /> },
  { v: 5, c: <FontAwesomeIcon icon={faArrowUp} /> },
  /*{
    v: 1,
    c: <C1 />,
  },
  {
    v: 2,
    c: (
      <Farb>
        <C2 />
      </Farb>
    ),
  },
  {
    v: 3,
    c: (
      <Farb>
        <C3 />
      </Farb>
    ),
  },
  {
    v: 4,
    c: (
      <Farb>
        <C4 />
      </Farb>
    ),
  },
  { v: 5, c: <D4 /> },*/
  { v: 6, c: <FontAwesomeIcon icon={faArrowDown} /> },
];

interface Props {
  size?: any;
  variante?: number;
}
export const VarianteIcon = React.memo<Props>(function VarianteIcon({
  size = "1em",
  variante = 1,
}) {
  return <>{mapping.find((m) => m.v === variante)?.c ?? <span>?</span>}</>;
});
