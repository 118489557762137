import { AT } from "../global/actionTypes";
import { Season } from "./seasonReducer";

export const putSeasons = (seasons: Season[]) => ({
  type: AT.PUT_SEASONS,
  payload: seasons,
});
export const putSeason = (season: Season) => ({
  type: AT.PUT_SEASON,
  payload: season,
});
export const announceNewSeason = (season: {
  seasonId: number;
  name: string;
}) => ({
  type: AT.ANNOUNCE_NEWSEASON,
  payload: season.name,
});

export const updateSeason = (season: Season) => ({
  type: AT.FETCH_SEASON_UPDATE,
  payload: season,
});
export const fetchSeasons = () => ({ type: AT.FETCH_SEASONS });
export const createSeason = (seasonName: string) => ({
  type: AT.FETCH_SEASON_ADD,
  payload: seasonName,
});
