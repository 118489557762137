import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../global/Store";
import {
  playerTitle,
  selectPlayer,
  selectPlayers,
} from "../../../players/playerSelector";
import { Player } from "../../../types/types";
import { useJassereiFormStyles } from "../../Common/useJassereiFormStyles";

interface Props {
  playerId?: number;
  setPlayer: (playerId: number) => void;
  informative: boolean;
}
export function PlayerSwitch(props: Props) {
  const classes = useJassereiFormStyles();

  const player = useSelector((state: RootState) =>
    selectPlayer(state, props.playerId)
  );

  const players = [undefined, ...useSelector(selectPlayers)];

  const onPlayerChanged = useCallback(
    (e: any) => props.setPlayer(Number(e.target.value)),
    [props]
  );

  return props.informative ? (
    <strong className={classes.bold} key={player?.userId}>
      {playerTitle(player)}
    </strong>
  ) : (
    <>
      <select
        className="player-dropdown"
        onChange={onPlayerChanged}
        value={player?.userId}
      >
        {players.map((s: Player | undefined) => (
          <option key={s?.userId ?? "default"} value={s?.userId}>
            {playerTitle(s)}
          </option>
        ))}
      </select>
    </>
  );
}
