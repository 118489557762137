import React, { useEffect } from "react";
import { RegisterSchieberPlayerComposer } from "./RegisterSchieberPlayerComposer";
import { RegisterSchieberForm } from "./RegisterSchieberForm";
import { selectEditSchieber } from "../../../games/gamesSelector";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveSeasons } from "../../../seasons/seasonSelector";
import {
  putRegisterSchieberFormValues,
  putSchieberPlayer,
} from "../../../games/registerGameActionCreators";
import { fetchPlayers } from "../../../players/playerActionCreators";

export function RegisterSchieberInit() {
  const dispatch = useDispatch();
  const schieber = useSelector(selectEditSchieber);
  const seasons = useSelector(selectActiveSeasons);

  useEffect(() => {
    // set default value to latest season, if possible
    if (!schieber.seasonId && seasons?.length > 0) {
      dispatch(
        putRegisterSchieberFormValues({ seasonId: seasons[0]?.seasonId })
      );
    }
  }, [dispatch, schieber, seasons]);

  useEffect(() => {
    dispatch(fetchPlayers());
  }, [dispatch]);

  const setter = (part: any) => dispatch(putRegisterSchieberFormValues(part));
  const setPlayer = (player: number, playerId: number) =>
    dispatch(putSchieberPlayer(player, playerId));
  return (
    <>
      <RegisterSchieberPlayerComposer
        schieber={schieber}
        setter={setter}
        playerSetter={setPlayer}
      />
      <RegisterSchieberForm
        schieber={schieber}
        setter={setter}
        seasons={seasons}
      />
    </>
  );
}
