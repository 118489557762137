import { Admin } from "./Admin";
import { AdminRequired } from "../Common/AdminRequired";

export const InitClub = () => (
  <>
    <h2>Willkomme</h2>
    <AdminRequired>
      <strong style={{ textAlign: "center", display: "block" }}>
        Do chasch din Verein fertig irichte.
        <br />
        Für en Schieber bruchts schomol 4 Benutzer!
      </strong>
      <hr />

      <Admin />
    </AdminRequired>
  </>
);
