import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchSchiebers } from "../../games/gamesActionCreators";
import { SchieberSession } from "../../games/gamesReducer";
import { selectSchiebers } from "../../games/gamesSelector";
import { SchiebersTeamCell } from "./SchiebersTeamCell";

interface Props {
  schiebers: SchieberSession[];
}
export function Schiebers() {
  const dispatch = useDispatch();

  const schiebers = useSelector(selectSchiebers);

  useEffect(() => {
    dispatch(fetchSchiebers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return schiebers ? (
    <SchiebersInternal schiebers={schiebers}></SchiebersInternal>
  ) : (
    <div>Nüt gfonde</div>
  );
}

function SchiebersInternal(props: Props) {
  const history = useHistory();

  const gotoDetails = useCallback(
    (schieber: SchieberSession) =>
      history.push(`/schieber/${schieber.sessionId}`),
    [history]
  );

  return (
    <>
      <table className="table table-striped" aria-labelledby="tableLabel">
        <thead>
          <tr>
            <th className="d-none d-sm-table-cell">Datum</th>
            <th className="d-none d-sm-table-cell">Bemerkige</th>
            <th className="d-none d-sm-table-cell">Bis</th>
            <th className="d-none d-sm-table-cell">Team 1</th>
            <th className="d-none d-sm-table-cell">Team 2</th>
          </tr>
        </thead>
        <tbody>
          {props.schiebers.map((session: SchieberSession) => (
            <tr onClick={() => gotoDetails(session)} key={session?.sessionId}>
              <td>{session?.started}</td>
              <td className="d-none d-sm-table-cell">{session?.remarks}</td>
              <td className="d-none d-sm-table-cell">{session?.pointsGoal}</td>
              <SchiebersTeamCell team={session?.team1}>
                {session?.team1Result}
              </SchiebersTeamCell>
              <SchiebersTeamCell team={session?.team2}>
                {session?.team2Result}
              </SchiebersTeamCell>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
