import React from "react";

interface Props {
  size?: any;
}
export const Herz = React.memo<Props>(function Herz({ size = "1em" }) {
  return (
    <svg
      viewBox="0 0 256 256"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Heart">
        <path
          stroke="#000000"
          id="svg_14"
          d="m90.84124,225.50523c-66.77297,-59.49423 -90.64696,-97.12921 -90.84045,-143.20123c-0.17644,-42.0003 29.29477,-82.53676 59.83649,-82.303c15.25117,0.11697 47.96591,15.57538 59.55031,28.13918c5.8369,6.33037 8.60154,5.70613 21.59678,-4.87643c35.36756,-28.80121 69.90435,-29.40573 92.27189,-1.61498c35.74808,44.41546 29.23975,97.45563 -18.70924,152.4726c-25.49244,29.25022 -81.16234,81.14175 -87.04987,81.14175c-1.7915,0 -18.28664,-13.39109 -36.65591,-29.75792l0,0.00001z"
          strokeWidth="0"
          fill="#ff0000"
        />
      </g>
    </svg>
  );
});
