import React from "react";
import "../../App.scss";
import { Link } from "react-router-dom";

interface Props {
  children: any;
  to: string;
}
export function NavItemLink(props: Props) {
  return (
    <li className="j-navbar-item nav-item">
      <Link className="j-navbar-link nav-link " to={props.to}>
        {props.children}
      </Link>
    </li>
  );
}
