import React from "react";

interface Props {
  size?: any;
}
export const Schufle = React.memo<Props>(function Schufle({ size = "1em" }) {
  return (
    <svg
      viewBox="0 0 256 256"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Spade">
        <path
          stroke="#000000"
          id="svg_16"
          d="m77.11112,244.55631c16.35977,-15.15552 28.32272,-34.34753 38.34659,-53.79373c6.12336,-7.75605 0.32605,-14.54141 -8.01432,-7.96441c-21.43464,13.73863 -50.61212,18.45 -73.81956,5.91591c-26.23513,-13.02314 -38.06576,-43.99077 -32.11325,-70.97412c3.76966,-27.34526 24.50201,-48.7796 47.01243,-64.17645c25.85816,-17.75737 53.78176,-33.00128 77.41462,-53.56351c9.86776,0.1899 17.29412,15.05482 27.91397,18.9884c28.19857,20.16257 61.61644,35.00385 83.82198,61.96493c11.21863,15.3627 17.61261,34.27998 18.32643,52.97998c-3.41809,25.35858 -19.16937,52.10245 -46.33264,59.32406c-23.48181,6.82052 -47.64102,-1.8293 -68.10379,-12.83375c-2.49945,-0.51494 -11.6671,-7.44845 -8.03372,-2.06007c12.23329,24.09975 25.63687,48.03026 45.04868,67.5979c1.14097,2.47241 10.08925,8.33818 5.32696,8.24499c-39.85809,0 -79.71605,0 -119.57416,0c4.25982,-3.21669 8.5198,-6.43346 12.77978,-9.65015l0,0.00001z"
          strokeWidth="0"
          fill="#000000"
        />
      </g>
    </svg>
  );
});
