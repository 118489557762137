import React, { useCallback, useEffect } from "react";
import { faCrown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchUsers } from "../../admin/adminActionCreators";
import { selectUsers } from "../../admin/adminSelector";
import { User } from "../../types/types";
import { useJassereiFormStyles } from "../Common/useJassereiFormStyles";

interface Props {
  users: User[];
}
export function ManageUsers() {
  const dispatch = useDispatch();

  const users = useSelector(selectUsers);

  useEffect(() => {
    dispatch(fetchUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return users ? (
    <ManageUsersInternal users={users}></ManageUsersInternal>
  ) : (
    <div>Keine Benutzer gefunden</div>
  );
}

function ManageUsersInternal(props: Props) {
  const classes = useJassereiFormStyles();
  const history = useHistory();

  const gotoDetails = useCallback(
    (user: User) => history.push(`/admin/user/${user.userId}`),
    [history]
  );

  return (
    <table className={"table table-striped " + classes.overViewTable}>
      <thead>
        <tr>
          <th></th>
          <th>Spitzname</th>
          <th>Vorname</th>
          <th>Nachname</th>
        </tr>
      </thead>
      <tbody>
        {props.users.map((user: User) => (
          <tr
            onClick={() => gotoDetails(user)}
            key={user.userId}
            className={user.active ? "active-user" : "inactive-user"}
          >
            <td>
              {user.admin && <FontAwesomeIcon icon={faCrown} />}
              {!user.active && <FontAwesomeIcon icon={faTimes} />}
            </td>
            <td>{user.nickname}</td>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
