import { all, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AT } from "../global/actionTypes";
import { watchApiCall } from "../util/apiUtil";
import { createUser, loadUsers, updateUser } from "../api/adminApi";
import { announceNewUser, putUsers } from "./adminActionCreators";
import { createResponseMessage } from "../api/apiActionCreator";
import { history } from "../global/Store";

export function* fetchUsersSaga() {
  yield watchApiCall(loadUsers(), putUsers);
}
export function* watchFetchUsersSaga() {
  yield takeEvery(AT.FETCH_USERS, fetchUsersSaga);
}

export function* fetchUpdateUserSaga(user: any) {
  yield watchApiCall(
    updateUser(user),
    createResponseMessage("Benutzer gespeichert.", "Konnte nicht speichern.")
  );
}
export function* watchFetchUpdateUserSaga() {
  yield takeEvery(AT.FETCH_USER_UPDATE, (action: any) =>
    fetchUpdateUserSaga(action.payload)
  );
}

export function* fetchCreateUserSaga(user: any) {
  yield watchApiCall(createUser(user), announceNewUser);
}
export function* watchFetchCreateUserSaga() {
  yield takeEvery(AT.FETCH_USER_CREATE, (action: any) =>
    fetchCreateUserSaga(action.payload)
  );
}

export function* addedUser(user: any) {
  yield fetchUsersSaga();
  history.push("/admin/user/" + user.userId);
  yield put(
    createResponseMessage(
      "Benutzer erstellt",
      "Konnte nicht erstellt werden."
    )(user)
  );
}
export function* watchAddedUser() {
  yield takeLatest(AT.ANNOUNCE_NEWUSER, (action: any) =>
    addedUser(action.payload)
  );
}

export default function* rootAdminSaga() {
  yield all([
    watchFetchUsersSaga(),
    watchFetchUpdateUserSaga(),
    watchFetchCreateUserSaga(),
    watchAddedUser(),
  ]);
}
