import React, { useMemo } from "react";
import moment from "moment";
import { PlayerWidget } from "../Common/PlayerWidget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

interface Props {
  playerId?: number;
  date: any;
}
export function SchieberDraftApproval(props: Props) {
  const formattedDate = useMemo(
    // eslint-disable-next-line no-restricted-syntax
    () => moment(props.date).format("D.M. HH:mm"),
    [props.date]
  );

  return (
    <>
      {props.date && (
        <tr className="timeline-approval">
          <td>
            <FontAwesomeIcon icon={faCheck} />
          </td>
          <td colSpan={2} className={"timeline-left"}>
            <PlayerWidget playerId={props.playerId} />
            <span className={"timeline-time-indication"}>{formattedDate}</span>
          </td>
        </tr>
      )}
    </>
  );
}
