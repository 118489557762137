import React, { useCallback, useMemo, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { RootState } from "../../global/Store";
import { selectSeason } from "../../seasons/seasonSelector";
import { createSeason } from "../../seasons/seasonActionCreators";
import { useJassereiFormStyles } from "../Common/useJassereiFormStyles";

export function CreateSeason() {
  const styles = useJassereiFormStyles();
  const dispatch = useDispatch();

  const [seasonName, setSeasonName] = useState("");
  const existingSeason = useSelector((state: RootState) =>
    selectSeason(state, seasonName)
  );

  const validSeasonName = useMemo(
    () => seasonName && seasonName.length > 0 && !existingSeason,
    [seasonName, existingSeason]
  );

  const handleChange = (e: any) => setSeasonName(e?.target?.value);
  const create = useCallback(
    (e: any) => {
      dispatch(createSeason(seasonName));
    },
    [dispatch, seasonName]
  );

  return (
    <>
      <TextField
        size="small"
        id="seasonName"
        name="seasonName"
        className={styles.simpleCreateTextfield}
        label="Name"
        variant="outlined"
        onChange={handleChange}
        value={seasonName}
        onSubmit={create}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={create}
        disabled={!validSeasonName}
        startIcon={<FontAwesomeIcon icon={faPlus} />}
      >
        Erstelle
      </Button>
    </>
  );
}
