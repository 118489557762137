import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  isHandValid,
  selectCurrentUiIndex,
  selectEditSchieber,
  selectIsGoalReached,
  selectLastHand,
} from "../../../games/gamesSelector";
import { Swiper, SwiperSlide } from "swiper/react";
import { RegisterSchieberSummary } from "./RegisterSchieberSummary";
import { RegisterSchieberHand } from "./RegisterSchieberHand";
import {
  addHand,
  removeLastHand,
} from "../../../games/registerGameActionCreators";
import { setUiIndex } from "../../../games/gamesActionCreators";
import { SchieberStrichliJassTafel } from "../SchieberStrichliJassTafel";
import { SchieberLineGraph2 } from "../SchieberLineGraph2";
import { Matchblatt } from "../Timeline/Matchblatt";

// swiper bundle styles
import "swiper/swiper-bundle.min.css";
// swiper core styles
import "swiper/swiper.min.css";

export function RegisterSchieber() {
  const dispatch = useDispatch();
  const history = useHistory();

  const uiIndex = useSelector(selectCurrentUiIndex);
  const schieber = useSelector(selectEditSchieber);
  const goalReached = useSelector(selectIsGoalReached);
  const isLastHandValid = isHandValid(useSelector(selectLastHand));

  const [swiper, setSwiper] = useState(null as any);

  const mobile = window.matchMedia("(min-width: 0px) and (max-width: 768px)");

  useEffect(() => {
    if (!schieber.sessionId) {
      history.push("/neu");
    }
  }, [history, schieber]);

  useEffect(() => {
    swiper?.slideTo(uiIndex);
  }, [swiper, uiIndex]);

  useEffect(() => {
    if (isLastHandValid && !goalReached) {
      dispatch(addHand());
    }
  }, [dispatch, goalReached, isLastHandValid]);

  useEffect(() => {
    if (goalReached && !isLastHandValid) {
      dispatch(removeLastHand());
    }
  }, [dispatch, goalReached, isLastHandValid]);

  const onSlideChange = (swiper: any) => {
    dispatch(setUiIndex(swiper.activeIndex));
  };
  const onSliderMove = (swiper: any) => {};

  return (
    <Swiper
      spaceBetween={80}
      slidesPerView={1}
      onSwiper={setSwiper}
      onSlideChange={onSlideChange}
      onSliderMove={onSliderMove}
      threshold={mobile?.matches ? 20 : 200}
      autoHeight
      style={{ height: "auto", minHeight: "100%" }}
    >
      {schieber?.hand?.map((hand) => (
        <SwiperSlide key={hand.no}>
          <RegisterSchieberHand no={hand.no} />
        </SwiperSlide>
      ))}
      {schieber.sessionId && (
        <>
          <SwiperSlide key="summary">
            <RegisterSchieberSummary />
          </SwiperSlide>
          <SwiperSlide key="timeline">
            <Matchblatt session={schieber} />
          </SwiperSlide>
          <SwiperSlide key="tafel">
            <SchieberStrichliJassTafel session={schieber} />
          </SwiperSlide>
          <SwiperSlide key="graph">
            <SchieberLineGraph2 schieber={schieber} />
          </SwiperSlide>
        </>
      )}
    </Swiper>
  );
}
