import React from "react";
import { RegisterSchieberHandWiisListItem } from "./RegisterSchieberHandWiisListItem";

import "./erfassen.scss";

interface Props {
  list: {
    betrag: number;
    playerId: number;
  }[];
  title?: string;
  deleteAction: (index: number) => any;
}
export function RegisterSchieberHandWiisList(props: Props) {
  return (
    <>
      <div>{props.title}</div>
      {props.list.map((w, index) => (
        <RegisterSchieberHandWiisListItem
          {...props}
          {...w}
          index={index}
          key={"wiis-" + index}
        />
      ))}
    </>
  );
}
