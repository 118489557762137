import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { About } from "./components/Public/About";
import { ApiStatusWidget } from "./components/Common/ApiStatusWidget";
import { AuthWidget } from "./components/Common/AuthWidget";
import { LoginPage } from "./components/Public/LoginPage";
import { Nav } from "./components/Navigation/Nav";
import { NavItemLink } from "./components/Navigation/NavItemLink";
import { NavTitle } from "./components/Navigation/NavTitle";
import { PlayerDetails } from "./components/Player/PlayerDetails";
import { Players } from "./components/Player/Players";
import { PrivateRoute } from "./components/Common/PrivateRoute";
import { Welcome } from "./components/Public/Welcome";
import { NavItems } from "./components/Navigation/NavItems";
import { UserWidget } from "./components/Public/UserWidget";
import { Schiebers } from "./components/Spiel/Schiebers";
import { SchieberDetails } from "./components/Spiel/SchieberDetails";
import { Admin } from "./components/Admin/Admin";
import { Body } from "./components/Common/Body";
import { NavToggler } from "./components/Navigation/NavToggler";
import { SchiebersLoader } from "./components/Spiel/SchiebersLoader";
import { PlayersLoader } from "./components/Player/PlayersLoader";
import { UserDetails } from "./components/User/UserDetails";
import { Footer } from "./components/Common/Footer";
import { Main } from "./components/Common/Main";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./global/Store";
import { SchieberDrafting } from "./components/Spiel/Drafting/SchieberDrafting";
import { RegisterSchieberFooter } from "./components/Spiel/Erfassen/RegisterSchieberFooter";
import { RegisterSchieber } from "./components/Spiel/Erfassen/RegisterSchieber";
import { ManageUser } from "./components/Admin/ManageUser";
import { AdminRequired } from "./components/Common/AdminRequired";
import { NewSchieberRegister } from "./components/Spiel/Erfassen/NewSchieberRegister";
import { AuthRequired } from "./components/Common/AuthRequired";
import { ManageSeason } from "./components/Admin/ManageSeason";
import { RegisterSchieberStaticSwiping } from "./components/Spiel/Erfassen/RegisterSchieberStaticSwiping";
import { Header } from "./components/Common/Header";
import { RegisterSchieberInit } from "./components/Spiel/Erfassen/RegisterSchieberInit";
import { LockSchieberRegister } from "./components/Spiel/Erfassen/LockSchieberRegister";
import { RegisterSchieberSwipingHelper } from "./components/Spiel/Erfassen/RegisterSchieberSwipingHelper";
import { currentSubDomain, getMainUrl } from "./util/commonUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchSeasons } from "./seasons/seasonActionCreators";
import { selectUser } from "./user/userSelector";
import { deleteAuthData } from "./user/userActionCreators";
import "./App.scss";
import { InitClub } from "./components/Admin/InitClub";
import { selectClubdetails } from "./clubdetails/clubdetailsSelector";
import { fetchPlayers } from "./players/playerActionCreators";
import { Enter } from "./components/Admin/Enter";

interface Props {
  subdomain: string;
}
export function ClubApp(props: Props) {
  const subdomain = currentSubDomain();
  const user = useSelector(selectUser);
  const club = useSelector(selectClubdetails);

  const dispatch = useDispatch();
  useEffect(() => {
    if (user && user?.shortName?.toLowerCase() !== subdomain?.toLowerCase()) {
      dispatch(deleteAuthData);
    }
  }, [dispatch, subdomain, user, user?.shortName]);

  useEffect(() => {
    dispatch(fetchSeasons());
    if (user) {
      dispatch(fetchPlayers());
    }
  }, [dispatch, user]);

  useEffect(() => {
    document.title = club?.longName ?? "Jasserei";
  }, [club?.longName]);

  const [hiddenMenu, setHiddenMenu] = useState(true);
  const toggle = useCallback(() => setHiddenMenu(!hiddenMenu), [hiddenMenu]);
  return (
    <>
      <Main>
        <ConnectedRouter history={history}>
          <Nav>
            <NavTitle>{props.subdomain}</NavTitle>
            <AuthRequired silent>
              <NewSchieberRegister>Neu</NewSchieberRegister>
            </AuthRequired>
            <NavToggler toggler={toggle}></NavToggler>
            <NavItems hidden={hiddenMenu} click={() => setHiddenMenu(true)}>
              <NavItemLink to="/players">Spieler</NavItemLink>
              <NavItemLink to="/schiebers">Resultat</NavItemLink>
              <NavItemLink to="/drafts">Entwürf</NavItemLink>
              <NavItemLink to="/erfassen">Erfasse</NavItemLink>
              <AdminRequired silent>
                <NavItemLink to="/admin">Admin</NavItemLink>
              </AdminRequired>
              <UserWidget />
            </NavItems>
          </Nav>
          <Header>
            <Route path="/erfassen*">
              <RegisterSchieberStaticSwiping />
            </Route>
            <Route path="/neu">
              <LockSchieberRegister />
            </Route>
            <ApiStatusWidget />
            <AuthWidget />
          </Header>
          <Body click={() => setHiddenMenu(true)}>
            <Switch>
              <Route path="/login">
                <LoginPage />
              </Route>
              <PrivateRoute path="/user">
                <UserDetails />
              </PrivateRoute>
              <Route path="/about">
                <About />
              </Route>
              <PrivateRoute path="/players">
                <PlayersLoader>Neu lade</PlayersLoader>
                <Players />
              </PrivateRoute>
              <PrivateRoute path="/player/:id">
                <PlayerDetails />
              </PrivateRoute>
              <PrivateRoute path="/schiebers">
                <SchiebersLoader>Neu lade</SchiebersLoader>
                <Schiebers />
              </PrivateRoute>
              <PrivateRoute path="/schieber/:id">
                <SchieberDetails />
              </PrivateRoute>
              <PrivateRoute path="/drafts">
                <SchieberDrafting />
              </PrivateRoute>
              <PrivateRoute path="/neu">
                <RegisterSchieberInit />
              </PrivateRoute>
              <PrivateRoute path="/erfassen">
                <RegisterSchieber />
              </PrivateRoute>
              <PrivateRoute path="/admin/user/:id">
                <AdminRequired>
                  <ManageUser />
                </AdminRequired>
              </PrivateRoute>
              <PrivateRoute path="/admin/season/:id">
                <AdminRequired>
                  <ManageSeason />
                </AdminRequired>
              </PrivateRoute>
              <PrivateRoute path="/admin/:tab">
                <AdminRequired>
                  <Admin />
                </AdminRequired>
              </PrivateRoute>
              <PrivateRoute path="/admin">
                <AdminRequired>
                  <Admin />
                </AdminRequired>
              </PrivateRoute>
              <Route path="/reenter">
                <Enter>
                  <Redirect
                    to={{
                      pathname: "/",
                    }}
                  />
                </Enter>
              </Route>
              <Route path="/enter">
                <Enter>
                  <Redirect
                    to={{
                      pathname: "/init",
                    }}
                  />
                </Enter>
              </Route>
              <Route path="/init">
                <InitClub />
              </Route>
              <Route path="*">
                <Welcome />
              </Route>
            </Switch>
          </Body>
          <Footer>
            <Switch>
              <Route path="/erfassen*">
                <RegisterSchieberSwipingHelper />
                <RegisterSchieberFooter />
              </Route>
              <Route path={"/neu"}></Route>
              <Route path={"*"}>
                <a href={getMainUrl()}>
                  <FontAwesomeIcon icon={faHome} />
                </a>
                <div style={{ display: "none" }}>
                  <label>sg-hash:</label> <code>?9yd??Zpf???K?c?m???</code>
                </div>
              </Route>
            </Switch>
          </Footer>
        </ConnectedRouter>
      </Main>
    </>
  );
}
export default ClubApp;
