import React from "react";
import { useSelector } from "react-redux";
import { selectEditSchieber } from "../../../games/gamesSelector";
import { RegisterSchieberPaginator } from "./Navigator/RegisterSchieberPaginator";
import { RegisterSchieberStanding } from "./RegisterSchieberStanding";

import "./erfassen.scss";

export function RegisterSchieberFooter() {
  const schieber = useSelector(selectEditSchieber);

  return (
    <>
      {schieber.sessionId && <RegisterSchieberStanding />}
      <RegisterSchieberPaginator />
    </>
  );
}
