import React from "react";
import { useSelector } from "react-redux";
import { SchieberSession } from "../../../games/gamesReducer";
import { RootState } from "../../../global/Store";
import { selectTeamTitle } from "../../../players/playerSelector";

interface Props {
  session: SchieberSession;
}
export function SchieberTimelineTeams2(props: Props) {
  const team1 = useSelector((state: RootState) =>
    selectTeamTitle(state, props.session.team1)
  );
  const team2 = useSelector((state: RootState) =>
    selectTeamTitle(state, props.session.team2)
  );

  return (
    <>
      <tr>
        <td
          colSpan={5}
          style={{
            textAlign: "right",
            borderTop: "2px solid",
            borderLeft: "2px solid",
            borderRight: "2px solid",
            fontWeight: "bold",
            paddingRight: "1em",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {team2}
        </td>
      </tr>
      <tr>
        <td
          colSpan={4}
          style={{
            textAlign: "right",
            borderTop: "2px solid",
            borderLeft: "2px solid",
            borderRight: "2px solid",
            fontWeight: "bold",
            paddingRight: "1em",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {team1}
        </td>
        <td
          style={{
            borderRight: "2px solid",
          }}
        ></td>
      </tr>
      <tr>
        <td
          colSpan={3}
          style={{
            borderBottom: " 2px solid",
            borderLeft: "2px solid",
          }}
        ></td>
        <td
          style={{
            borderRight: "2px solid",
          }}
        ></td>
        <td
          style={{
            borderRight: "2px solid",
          }}
        ></td>
      </tr>
    </>
  );
}
