import { all, takeEvery, takeLatest } from "redux-saga/effects";
import { AT } from "../global/actionTypes";
import { watchApiCall } from "../util/apiUtil";
import { loadClubs } from "../api/clubsApi";
import { putClubs } from "./clubsActionCreators";
import { createClub } from "../api/clubdetailsApi";
import { announceNewClub } from "../clubdetails/clubdetailsActionCreators";

export function* fetchClubsSaga() {
  yield watchApiCall(loadClubs(), putClubs);
}
export function* watchFetchClubsSaga() {
  yield takeEvery(AT.FETCH_CLUBS, fetchClubsSaga);
}

export function* fetchCreateClubSaga(club: any) {
  yield watchApiCall(createClub(club), announceNewClub);
}
export function* watchFetchCreateClubSaga() {
  yield takeEvery(AT.FETCH_CLUBDETAILS_ADD, (action: any) =>
    fetchCreateClubSaga(action.payload.club)
  );
}

export function* setClub(authData: any) {
  yield fetchClubsSaga();
}
export function* watchAuth() {
  yield takeLatest(AT.PUT_AUTH, (action: any) => setClub(action.payload));
}

export default function* rootClubsSaga() {
  yield all([watchFetchClubsSaga(), watchFetchCreateClubSaga(), watchAuth()]);
}
