import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectDrafts } from "../../../games/draftSelector";
import { fetchSchieberDrafts } from "../../../games/gamesActionCreators";
import { SchieberSession } from "../../../games/gamesReducer";
import { SchieberDraftListRow } from "./SchieberDraftListRow";

import "./drafts.scss";
interface Props {
  drafts: SchieberSession[];
}
export function SchieberDraftList() {
  const dispatch = useDispatch();

  const drafts = useSelector(selectDrafts);

  useEffect(() => {
    dispatch(fetchSchieberDrafts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return drafts ? <SchieberDraftListInternal drafts={drafts} /> : <div>-</div>;
}

function SchieberDraftListInternal(props: Props) {
  return (
    <table className="j-table" aria-labelledby="tableLabel">
      <thead>
        <tr>
          <th></th>
          <th>Datum</th>
          <th className="j-additional">Bemerkige</th>
          <th>Team 1</th>
          <th>Team 2</th>
        </tr>
      </thead>
      {props.drafts.map((session: SchieberSession) => (
        <SchieberDraftListRow session={session} key={session.sessionId} />
      ))}
    </table>
  );
}
