import React from "react";

interface Props {
  size?: any;
}
export const Egge = React.memo<Props>(function Egge({ size = "1em" }) {
  return (
    <svg
      viewBox="0 0 256 256"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Diamond">
        <path
          strokeWidth="0"
          stroke="#000000"
          id="svg_12"
          d="m59,130.92181c15.50808,-48.47983 43.46833,-89.85739 67.25479,-130.92181c27.67048,26.35255 51.57623,84.32768 72.37439,124.70612c-18.55056,46.70482 -45.25373,89.24015 -69.30977,131.12611c-25.93285,-38.32174 -48.10965,-82.10508 -70.3194,-124.91042l-0.00001,0z"
          fill="#ff0000"
        />
      </g>
    </svg>
  );
});
