import {
  faChevronLeft,
  faChevronRight,
  faHandPointer,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUiIndex } from "../../../games/gamesActionCreators";
import {
  isHandValid,
  selectCurrentUiIndex,
  selectEditSchieber,
  selectIsGoalReached,
  selectSchieberHand,
} from "../../../games/gamesSelector";
import { RootState } from "../../../global/Store";
import { RegisterSchieberNavigatorTile } from "./Navigator/RegisterSchieberNavigatorTile";

export function RegisterSchieberSwipingHelper() {
  const dispatch = useDispatch();
  const schieber = useSelector(selectEditSchieber);

  const uiIndex = useSelector(selectCurrentUiIndex);
  const hand = useSelector((state: RootState) =>
    selectSchieberHand(state, uiIndex + 1)
  );
  const finito = useSelector(selectIsGoalReached);
  const valid = isHandValid(hand);
  const content = useMemo(() => {
    if (uiIndex <= schieber.hand.length - 2) {
      if (valid) {
        return { a: "Witerschriibe", b: "right" };
      }
      return { a: "Stäche Wiise Schriibe! ", b: "none" };
    }
    if (uiIndex === schieber.hand.length && !finito) {
      return { a: "Zrugg zum schriibe", b: "left" };
    }
    if (uiIndex > schieber.hand.length) {
      return { a: "Zrugg zum schriibe", b: "left" };
    }
    return undefined;
  }, [finito, schieber.hand.length, uiIndex, valid]);

  const goToCurrent = useCallback(
    () => dispatch(setUiIndex(schieber.hand.length - 1)),
    [dispatch, schieber.hand.length]
  );
  return (
    <>
      {content && (
        <div className="nav-small">
          <RegisterSchieberNavigatorTile
            perRow={1}
            action={goToCurrent}
            key={"back"}
            swipethize
          >
            {content.b === "left" && (
              <>
                <FontAwesomeIcon icon={faChevronLeft} />
                &nbsp;
                <FontAwesomeIcon icon={faChevronLeft} />
                &nbsp;
                <FontAwesomeIcon icon={faHandPointer} />
                &nbsp;
              </>
            )}
            {content.a}
            {content.b === "right" && (
              <>
                &nbsp;
                <FontAwesomeIcon icon={faHandPointer} />
                &nbsp;
                <FontAwesomeIcon icon={faChevronRight} />
                &nbsp;
                <FontAwesomeIcon icon={faChevronRight} />
              </>
            )}
          </RegisterSchieberNavigatorTile>
        </div>
      )}
    </>
  );
}
