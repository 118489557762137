import React, { useCallback } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { putCurrentSchieber } from "../../../games/gamesActionCreators";
import { initialSchieberCreator } from "../../../games/gamesReducer";
import { pendingChanges } from "../../../games/gamesSelector";
import { PromptedButton } from "../../Common/PromptedButton";

export function NewSchieberRegister(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();

  const hasChanges = useSelector(pendingChanges);

  const reset = useCallback(() => {
    dispatch(putCurrentSchieber(initialSchieberCreator()));
    history.push("/neu");
  }, [history, dispatch]);

  return (
    <>
      <PromptedButton
        color="secondary"
        okFn={reset}
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        promptText={
          "Wotsch wörkli neu afange mit Erfasse? Ned gspeichereti Änderige gönd verlore."
        }
        needsPrompt={hasChanges}
      >
        {props.children}
      </PromptedButton>
    </>
  );
}
