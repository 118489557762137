import { all, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { AT } from "../global/actionTypes";
import { watchApiCall } from "../util/apiUtil";
import { createResponseMessage } from "../api/apiActionCreator";
import {
  announceClubUpdate,
  putClubdetails,
} from "./clubdetailsActionCreators";
import { loadClubdetails, updateClubdetails } from "../api/clubdetailsApi";
import {
  currentSubDomain,
  getSubDomainUrl,
  serializeObjectToQuerystring,
} from "../util/commonUtil";
import { selectUser } from "../user/userSelector";
import { AuthUser } from "../types/types";

export function* fetchClubdetailsSaga(data: any) {
  yield watchApiCall(loadClubdetails(data), putClubdetails);
}
export function* watchFetchClubdetailsSaga() {
  yield takeEvery(AT.FETCH_CLUBDETAILS, (action: any) =>
    fetchClubdetailsSaga(action.payload)
  );
}

export function* fetchUpdateClubdetailsSaga(data: any) {
  yield watchApiCall(
    updateClubdetails(data),
    announceClubUpdate,
    (result: any) =>
      put(createResponseMessage("Date sind apasst", "Ned chöne apasse")(result))
  );
}
export function* watchFetchUpdateClubdetailsSaga() {
  yield takeEvery(AT.FETCH_CLUBDETAILS_UPDATE, (action: any) =>
    fetchUpdateClubdetailsSaga(action.payload)
  );
}

export function* setClub(user: AuthUser, path: string) {
  const href =
    getSubDomainUrl(user.shortName) +
    path +
    "?" +
    serializeObjectToQuerystring(user);
  document.location = href;
}
export function* watchNewClub() {
  yield takeEvery(AT.ANNOUNCE_NEWCLUB, (action: any) =>
    setClub(action.payload, "/enter")
  );
}

export function* checkbackClub(clubShortName: string) {
  if (
    clubShortName &&
    currentSubDomain()?.toLowerCase() !== clubShortName?.toLowerCase()
  ) {
    const user: AuthUser = yield select(selectUser);
    yield setClub({ ...user, shortName: clubShortName }, "/reenter");
  }
}
export function* watchClubshortnameChange() {
  yield takeLatest(AT.ANNOUNCE_CLUBUPDATE, (action: any) =>
    checkbackClub(action.payload)
  );
}

export function* watchPutClubUpdate() {
  yield takeLatest(AT.ANNOUNCE_CLUBUPDATE, (action: any) =>
    fetchClubdetailsSaga({ shortName: action.payload })
  );
}

export default function* rootClubdetailsSaga() {
  yield all([
    watchFetchClubdetailsSaga(),
    watchPutClubUpdate(),
    watchClubshortnameChange(),
    watchFetchUpdateClubdetailsSaga(),
    watchNewClub(),
  ]);
}
