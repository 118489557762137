import React from "react";
import { useSelector } from "react-redux";
import { SessionTeam } from "../../games/gamesReducer";
import { RootState } from "../../global/Store";
import { playerTitle, selectPlayer } from "../../players/playerSelector";

interface Props {
  team: SessionTeam;
  children?: any;
  onClick?: () => any;
}
export function SchiebersTeamCell(props: Props) {
  const player1 = useSelector((state: RootState) =>
    selectPlayer(state, props?.team?.player1Id)
  );
  const player2 = useSelector((state: RootState) =>
    selectPlayer(state, props?.team?.player2Id)
  );

  return (
    <td onClick={props.onClick}>
      <span className="badge badge-pill badge-primary j-mobile">
        {player1?.shortName}
      </span>
      <span className="badge badge-pill badge-primary j-additional">
        {playerTitle(player1)}
      </span>
      <span className="badge badge-pill badge-primary j-mobile">
        {player2?.shortName}
      </span>
      <span className="badge badge-pill badge-primary j-additional">
        {playerTitle(player2)}
      </span>
      {props.children}
    </td>
  );
}
