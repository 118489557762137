import React, { useCallback, useMemo, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../../admin/adminActionCreators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { selectUserByName } from "../../admin/adminSelector";
import { RootState } from "../../global/Store";
import { useJassereiFormStyles } from "../Common/useJassereiFormStyles";

export function CreateUser() {
  const classes = useJassereiFormStyles();
  const dispatch = useDispatch();

  const [nickname, setNickname] = useState("");
  const existingUser = useSelector((state: RootState) =>
    selectUserByName(state, nickname)
  );

  const validNickname = useMemo(
    () => nickname && nickname.length > 0 && !existingUser,
    [nickname, existingUser]
  );

  const create = useCallback(
    (e: any) => {
      dispatch(createUser(nickname));
    },
    [dispatch, nickname]
  );
  const handleChange = (e: any) => setNickname(e?.target?.value);

  return (
    <>
      <TextField
        size="small"
        id="nickname"
        name="nickname"
        className={classes.simpleCreateTextfield}
        label="Benutzername"
        variant="outlined"
        onChange={handleChange}
        value={nickname}
        onSubmit={create}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={create}
        disabled={!validNickname}
        startIcon={<FontAwesomeIcon icon={faPlus} />}
      >
        Erstelle
      </Button>
    </>
  );
}
