import React from "react";

export function Developer() {
  const push = () =>
    Notification.requestPermission().then(() => new Notification("Hey 👋"));
  const delayedPush = () => setTimeout(push, 10000);

  return (
    <>
      <h3>Technische Eckdaten</h3>
      <ul>
        <li>
          <strong>ASP.NET 5.0</strong> Server Runtime von <code>Microsoft</code>{" "}
          - Platformunabhängig lauffähig
        </li>
        <li>
          <strong>MS Identity</strong> Security von <code>Microsoft</code>
        </li>
        <li>
          <strong>Entity Framework</strong> mit <code>MS SQL</code>. Model first
          Datenbank mit EF Migrations
        </li>
        <li>
          <strong>Single Page Web Application</strong>
          <code> SPA</code>
        </li>
        <li>
          <strong>React 17</strong> SPA mit <code>Redux, Saga</code>
        </li>
        <li>
          <strong>Bootstrap</strong> Stylesheets Framework von{" "}
          <code>Twitter</code>
        </li>
        <li>
          <strong>Material Design</strong> UI Komponenten
        </li>
        <li>
          <strong>GIT/DevOps</strong> Sourceverwaltung in{" "}
          <code>Azure DevOps</code> über GIT{" "}
        </li>
      </ul>
      <div onClick={push}>Push</div>
      <div onClick={delayedPush}>Push nach 10sec</div>
    </>
  );
}
