import React, { useMemo } from "react";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SchieberHand } from "../../../games/gamesReducer";
import {
  selectPointsByHandAndUser,
  team1sumreducerlightFn,
  team2sumreducerlightFn,
} from "../../../games/gamesSelector";
import { VarianteIcon } from "../../Assets/VarianteIcon";
import { PlayerWidget } from "../../Common/PlayerWidget";

import "../timeline.scss";
import { SchieberTimelineWeis2 } from "./SchieberTimelineWeis2";
import { useSelector } from "react-redux";
import { RootState } from "../../../global/Store";

interface Props {
  hand: SchieberHand;
}
export function SchieberTimelineHand2(props: Props) {
  const points = useSelector((state: RootState) =>
    selectPointsByHandAndUser(state, props.hand, props.hand.playerDragsiId)
  );
  const player = useMemo(
    () => props.hand?.playerGschobeId ?? props.hand?.playerDragsiId,
    [props.hand?.playerDragsiId, props.hand?.playerGschobeId]
  );
  return (
    <>
      <tr
        style={{
          fontWeight: "bold",
          borderTop: "1px dotted",
        }}
      >
        <td
          style={{
            textAlign: "left",
          }}
        >
          <VarianteIcon variante={props.hand.variante}></VarianteIcon>&nbsp;
          {points}
        </td>
        <td>
          {props.hand?.playerGschobeId && (
            <FontAwesomeIcon icon={faArrowCircleRight} />
          )}
        </td>
        <td
          style={{
            textAlign: "left",
          }}
        >
          <div
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            <PlayerWidget playerId={player} />
          </div>
        </td>
        <td
          style={{
            borderLeft: "2px solid",
            borderRight: "2px solid",
            textAlign: "right",
          }}
        >
          {team1sumreducerlightFn(0, props.hand)}
        </td>
        <td
          style={{
            borderLeft: "2px solid",
            borderRight: "2px solid",
            textAlign: "right",
          }}
        >
          {team2sumreducerlightFn(0, props.hand)}
        </td>
      </tr>
      <SchieberTimelineWeis2 hand={props.hand}></SchieberTimelineWeis2>
    </>
  );
}
