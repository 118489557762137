import { Switch } from "@material-ui/core";
import { useState } from "react";
import { SchieberSession } from "../../../games/gamesReducer";
import { SchieberTimeline } from "./SchieberTimeline";
import { SchieberTimeline2 } from "./SchieberTimeline2";

interface Props {
  session: SchieberSession;
}
export function Matchblatt(props: Props) {
  const [variante, setVariante] = useState(false);
  return (
    <>
      <h3 className="timeline-header">
        Matchblatt
        <Switch
          size="small"
          checked={variante}
          onChange={() => setVariante(!variante)}
        />
      </h3>
      {variante ? (
        <SchieberTimeline {...props} />
      ) : (
        <SchieberTimeline2 {...props} />
      )}
    </>
  );
}
