import React from "react";
import { SchieberSession } from "../../../games/gamesReducer";
import { SchieberTimelineHand } from "./SchieberTimelineHand";
import { SchieberTimelineHeader } from "./SchieberTimelineHeader";
import { SchieberTimelineSummary } from "./SchieberTimelineSummary";
import { SchieberTimelineTeams } from "./SchieberTimelineTeams";

import "../timeline.scss";

interface Props {
  session: SchieberSession;
}
export function SchieberTimeline(props: Props) {
  return (
    <>
      <table className="timeline-list">
        <thead>
          <tr>
            <th className="timeline-betragspalte"></th>
            <th></th>
            <th className="timeline-betragspalte"></th>
          </tr>
        </thead>
        <tbody>
          <SchieberTimelineHeader {...props} />
          <SchieberTimelineTeams {...props} />
          {props?.session?.hand?.map((hand) => (
            <SchieberTimelineHand hand={hand} key={hand.no} />
          ))}
          <SchieberTimelineSummary {...props} />
        </tbody>
      </table>
    </>
  );
}
