import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../global/Store";
import { selectPlayerTitle } from "../../players/playerSelector";

interface Props {
  playerId?: number;
  className?: string;
}
export function PlayerWidget(props: Props) {
  const playerName = useSelector((state: RootState) =>
    selectPlayerTitle(state, props.playerId)
  );

  return <span className={props.className}>{playerName}&nbsp;</span>;
}
