import { User } from "../types/types";
import { fetchPost } from "../util/apiUtil";

export const authenticate = (data: {
  nickname: string;
  password: string;
  shortName: string;
}) => fetchPost("users/authenticate", data);

export const changePassword = (data: {
  oldPassword: string;
  newPassword: string;
}) => fetchPost("users/changePassword", data);

export const updateUser = (data: User) => fetchPost("users/update", data);
