import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

interface Props {
  open: boolean;
  promptTitle?: string;
  promptText: string;
  okFn: () => any;
  okText?: string;
  thirdFn?: () => any;
  thirdText?: string;
  cancelFn?: () => any;
  cancelText?: string;
  hideCancel?: boolean;
}
export const Prompter = React.memo(function Prompter(props: Props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.cancelFn}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {props.promptTitle ?? "Bestätigung"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.promptText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!props.hideCancel && (
          <Button onClick={props.cancelFn} color="primary">
            {props.cancelText ?? "Abbräche"}
          </Button>
        )}
        {props.thirdFn && (
          <Button onClick={props.thirdFn} color="secondary">
            {props.thirdText ?? "?"}
          </Button>
        )}
        <Button onClick={props.okFn} color="primary" autoFocus>
          {props.okText ?? "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
