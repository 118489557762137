import React from "react";
import { SchieberDraft, SchieberSession } from "../../games/gamesReducer";
import { SchieberDraftRow } from "./SchieberDraftRow";

interface Props {
  session: SchieberSession;
}
export function SchieberDraftsTable(props: Props) {
  return (
    <>
      <h3>Verlauf</h3>
      <table>
        <tbody>
          {props.session.draft?.map((draft: SchieberDraft) => (
            <SchieberDraftRow
              draft={draft}
              playerId1={props.session.team1.player1Id}
              playerId2={props.session.team1.player2Id}
              playerId3={props.session.team2.player1Id}
              playerId4={props.session.team2.player2Id}
              key={draft.createdOn}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}
