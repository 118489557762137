import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectApiStatus,
  selectBlocked,
  selectLatestResponse,
} from "../../api/apiSelector";
import { ApiStatus } from "../../types/types";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Backdrop, CircularProgress } from "@material-ui/core";

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function ApiStatusWidget() {
  const latestResponse = useSelector(selectLatestResponse);
  const apiStatus = useSelector(selectApiStatus);
  const criticalActionPending = useSelector(selectBlocked);
  const [state, setState] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleClose = (e: any, reason: any) => {
    setState({ ...state, open: false });
  };
  useEffect(() => {
    if (latestResponse) {
      setState({
        open: true,
        ...latestResponse,
      });
    }
  }, [latestResponse]);
  useEffect(() => {
    if (apiStatus === ApiStatus.failed) {
      setState({
        open: true,
        message: "Fehler bem Lade",
        severity: "warning",
      });
    }
    if (apiStatus === ApiStatus.authExpired) {
      setState({
        open: true,
        message: "Wer besch du überhaupt? Bitte neu ilogge",
        severity: "warning",
      });
    }
  }, [apiStatus]);

  return (
    <>
      <Backdrop style={{ zIndex: 1000 }} open={criticalActionPending}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={state.open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={state.severity}>
          {state.message}
        </Alert>
      </Snackbar>
    </>
  );
}
