import { AT } from "../global/actionTypes";
import { Player } from "../types/types";

export interface PlayerState {
  data: Player[];
}
export const playerReducer = (
  state: PlayerState = { data: [] },
  action: any
): PlayerState => {
  switch (action.type) {
    case AT.PUT_PLAYERS: {
      return { ...state, data: action.payload };
    }
    case AT.PUT_PLAYERDETAILS: {
      return {
        ...state,
        data: state.data.map((p: Player) =>
          p.userId === action.payload.userId ? action.payload : p
        ),
      };
    }
    case AT.FETCH_PLAYERS: {
      return { ...state };
    }
  }
  return { ...state };
};
