import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { selectUser } from "../../user/userSelector";

interface Props {
  children?: any;
  path: string;
}

export function PrivateRoute(props: Props) {
  const user = useSelector(selectUser);

  return user ? (
    <Route path={props.path}>{props.children}</Route>
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: {
          from: props.path,
        },
      }}
    />
  );
}
