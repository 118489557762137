import React from "react";
import { CurrentRegisterWidget } from "../Erfassen/CurrentRegisterWidget";
import { SchieberDraftList } from "./SchieberDraftList";
import { SchieberDraftsLoader } from "./SchieberDraftsLoader";

export function SchieberDrafting() {
  return (
    <>
      <CurrentRegisterWidget />
      <SchieberDraftsLoader>Neu lade</SchieberDraftsLoader>
      <SchieberDraftList />
    </>
  );
}
