import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../global/Store";
import { selectPlayerTitle } from "../../players/playerSelector";
import { PersonalSession, Player } from "../../types/types";
import { mode } from "../../util/commonUtil";
import { JasstafelContainer } from "../Common/JasstafelContainer";
import { ProfileEntry } from "./ProfileEntry";

interface Props {
  player: Player;
}
export function PlayerProfile(props: Props) {
  const sessions = useMemo(
    () => props.player?.sessions ?? [],
    [props.player?.sessions]
  );
  const vorhandRatio = useMemo(() => {
    if (!props.player.schieberStats) {
      return 0;
    }
    return (
      props.player.schieberStats?.gschobe /
      (props.player.schieberStats?.gschobe + props.player.schieberStats?.agseit)
    );
  }, [props.player.schieberStats]);
  const favPartnerId = useMemo(
    () => mode(sessions.map((s: PersonalSession) => s.partnerId)),
    [sessions]
  );
  const avgWiis = useMemo(
    () =>
      (props.player.schieberStats?.wiisTotal ?? 0) /
      sessions.reduce((p: number, c: PersonalSession) => p + c.hands, 0),
    [props.player.schieberStats?.wiisTotal, sessions]
  );

  const favPartner = useSelector((state: RootState) =>
    selectPlayerTitle(state, favPartnerId)
  );

  return (
    <JasstafelContainer>
      <h1>{props.player.displayName}</h1>
      <ProfileEntry label={"Trainingseinheite"} value={sessions.length} />
      {sessions.length ? (
        <>
          <ProfileEntry
            label={"W/L-Ratio"}
            value={
              sessions.filter((s: PersonalSession) => s.won).length /
              sessions.length
            }
            type={"percent"}
          />
          <ProfileEntry label={"Lieblingspartner"} text={favPartner} />
          <ProfileEntry
            label={"Vorhand-Ratio"}
            value={vorhandRatio}
            type={"percent"}
          />
          <ProfileEntry label={"Avg Wiis-Power"} value={avgWiis} />
        </>
      ) : (
        <></>
      )}
    </JasstafelContainer>
  );
}
