import React from "react";
import {
  removeWiisTeam1,
  removeWiisTeam2,
} from "../../../games/gamesActionCreators";
import { SchieberHand } from "../../../games/gamesReducer";
import { RegisterSchieberHandWiisList } from "./RegisterSchieberHandWiisList";

import "./erfassen.scss";

interface Props {
  hand: SchieberHand;
}
export function RegisterSchieberHandWiise(props: Props) {
  return (
    <>
      <RegisterSchieberHandWiisList
        list={props.hand.team1Wiis}
        deleteAction={(index: number) => removeWiisTeam1(index, props.hand.no)}
      />
      <RegisterSchieberHandWiisList
        list={props.hand.team2Wiis}
        deleteAction={(index: number) => removeWiisTeam2(index, props.hand.no)}
      />
    </>
  );
}
