import { AT } from "../global/actionTypes";

// TODO: gamesActionCreaters und diser hier sind mischmasch

export const putSchieberPlayer = (player: number, playerId: number) => ({
  type: AT.PUT_SCHIEBERPLAYER,
  payload: { player, playerId },
});

export const putRegisterSchieberFormValues = (values: any) => ({
  type: AT.PUT_SCHIEBERFORM,
  payload: values,
});

export const lockInput = () => ({
  type: AT.LOCK_SCHIEBERMETADATA,
});
export const saveSession = () => ({
  type: AT.SAVE_SCHIEBERSESSION,
});
export const addHand = () => ({
  type: AT.ADD_SCHIEBERHAND,
});
export const removeLastHand = () => ({
  type: AT.REMOVE_SCHIEBERHAND,
});

export const deleteSession = () => ({
  type: AT.DELETE_SCHIEBERSESSION,
});
