import { faStream } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { putCurrentSchieber } from "../../../games/gamesActionCreators";
import { SchieberDraft, SchieberSession } from "../../../games/gamesReducer";
import { Prompter } from "../../Common/Prompter";
import { SchiebersTeamCell } from "../SchiebersTeamCell";
import { SchieberDraftListDraftRow } from "./SchieberDraftListDraftRow";

interface Props {
  session: SchieberSession;
}
export function SchieberDraftListRow(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [expanded, setexpanded] = useState(false);

  const [promptOpen, setPromptOpen] = useState(false);

  const gotoDetails = useCallback(
    (schieber: SchieberSession) => {
      const load = schieber?.draft?.shift();

      if (load) {
        dispatch(
          putCurrentSchieber({
            ...JSON.parse(load.data),
          })
        );
      } else {
        dispatch(putCurrentSchieber(schieber));
      }
      history.push("/erfassen");
    },
    [history, dispatch]
  );

  const go = () => setPromptOpen(true);

  const expand = () => setexpanded(!expanded);

  return (
    <>
      <tbody className="j-group">
        <tr className="j-mainrow">
          <td
            style={{ width: "1.5em", borderRight: "2px solid" }}
            onClick={expand}
          >
            <FontAwesomeIcon icon={faStream} />
          </td>
          <td onClick={go}>{props.session?.started}</td>
          <td onClick={go} className="j-additional">
            {props.session?.remarks} ({props.session?.pointsGoal})
          </td>
          <SchiebersTeamCell team={props.session?.team1} onClick={go} />
          <SchiebersTeamCell team={props.session?.team2} onClick={go} />
        </tr>
        {expanded &&
          props.session.draft?.map((d: SchieberDraft) => (
            <SchieberDraftListDraftRow draft={d} />
          ))}
      </tbody>
      <Prompter
        open={promptOpen}
        okFn={() => gotoDetails(props.session)}
        cancelFn={() => setPromptOpen(false)}
        key={props.session?.sessionId}
        promptText={
          "Wotsch du de Entwurf lade? Ungspeichereti Resultat gönd verlore."
        }
      />
    </>
  );
}
