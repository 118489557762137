import { useJassereiFormStyles } from "../../Common/useJassereiFormStyles";

interface Props {
  value?: any;
  name: string;
  options: { value: any; label: string }[];
  setValue: (value: any) => void;
  informative: boolean;
  className?: any;
}
export function JSelect(props: Props) {
  const classes = useJassereiFormStyles();

  return props.informative ? (
    <strong className={classes.bold} key={props.value}>
      {props.options.find((o) => o.value === props.value)?.label}
    </strong>
  ) : (
    <>
      <select
        name={props.name}
        className={props.className}
        onChange={props.setValue}
        value={props.value}
      >
        {props.options.map((s) => (
          <option key={s?.value ?? "default"} value={s?.value}>
            {s.label}
          </option>
        ))}
      </select>
    </>
  );
}
