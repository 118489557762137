import React, { useMemo } from "react";
import { VarianteIcon } from "../../Assets/VarianteIcon";
import { SchieberHand } from "../../../games/gamesReducer";

import "./erfassen.scss";

interface Props {
  variante: number;
  hand: SchieberHand;
  chooseVariante: (variante: number) => any;
}
export function RegisterSchieberHandAsaegeFarb(props: Props) {
  const classNames = useMemo(
    () =>
      "asaege asaegefarbe " +
      (props.hand.variante
        ? props.hand.variante === props.variante
          ? "asaegeselected"
          : "asaegeunselected"
        : "asaegeoption"),
    [props.variante, props.hand.variante]
  );

  return (
    <div
      className={classNames}
      onClick={() => props.chooseVariante(props.variante)}
    >
      <VarianteIcon variante={props.variante}></VarianteIcon>
    </div>
  );
}
