import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUiIndex } from "../../../games/gamesActionCreators";
import {
  selectCurrentUiIndex,
  selectEditSchieber,
} from "../../../games/gamesSelector";
import { RegisterSchieberNavigatorTile } from "./Navigator/RegisterSchieberNavigatorTile";

export function RegisterSchieberStaticSwiping() {
  const dispatch = useDispatch();

  const schieber = useSelector(selectEditSchieber);

  const uiIndex = useSelector(selectCurrentUiIndex);

  return (
    <div className="nav-small desktop-only">
      <RegisterSchieberNavigatorTile
        perRow={2}
        disabled={uiIndex === 0}
        swipethize={true}
        action={() => dispatch(setUiIndex(uiIndex - 1))}
        key={"back"}
      >
        Zurück
      </RegisterSchieberNavigatorTile>
      <RegisterSchieberNavigatorTile
        perRow={2}
        disabled={uiIndex === schieber.hand.length + 3}
        swipethize={true}
        action={() => dispatch(setUiIndex(uiIndex + 1))}
        key={"forward"}
      >
        Weiter
      </RegisterSchieberNavigatorTile>
    </div>
  );
}
