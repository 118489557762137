import React, { useMemo } from "react";
import { baseUrl } from "../../util/apiUtil";

interface Props {
  guid: string;
  size: "s" | "m" | "l" | "xl" | "max";
}
export const Image = React.memo(function Image(props: Props) {
  const url = useMemo(() => {
    if (props.guid) {
      return baseUrl() + "media/" + props.guid;
    }
    return props.size === "s"
      ? "placeholder-small.png"
      : props.size === "m"
      ? "placeholder-medium.png"
      : "placeholder-large.png";
  }, [props.guid, props.size]);

  const className = useMemo(() => "image-" + props.size ?? "m", [props.size]);

  return <img className={className} src={url} />;
});
