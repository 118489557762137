import { AT } from "../global/actionTypes";
import { AuthUser } from "../types/types";

export interface UserState {
  currentUser?: AuthUser;
}

export const userReducer = (
  state: UserState = { currentUser: undefined },
  action: any
): UserState => {
  switch (action.type) {
    case AT.PUT_AUTH: {
      return { ...state, currentUser: action.payload };
    }
    case AT.ANNOUNCE_NEWCLUB: {
      return { ...state, currentUser: action.payload };
    }
    case AT.PUT_PERSONAL_DATA: {
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload },
      };
    }
    case AT.AUTH_EXPIRED: {
      return { ...state, currentUser: undefined };
    }
    case AT.DELETE_AUTH: {
      return { ...state, currentUser: undefined };
    }
  }
  return { ...state };
};
