import React, { useCallback, useMemo, useState } from "react";

import { Prompter } from "./Prompter";

type colorType = "primary" | "secondary" | "default" | undefined;

const mapColorToClass = (c: colorType) =>
  c === "default"
    ? "mainColor"
    : c === "primary"
    ? "mainColor"
    : c === "secondary"
    ? "secondaryColor"
    : "mainColor";
const mapBgColorToClass = (c: colorType) =>
  c === "default"
    ? "mainBgColor"
    : c === "primary"
    ? "mainBgColor"
    : c === "secondary"
    ? "secondaryBgColor"
    : "mainBgColor";

interface Props {
  children: any;
  needsPrompt?: boolean;
  color: colorType;
  className?: any;
  promptText?: string;
  okFn: () => any;
  okText?: string;
  thirdFn?: () => any;
  thirdText?: string;
  cancelText?: string;
  startIcon: any;
  disabled?: boolean;
  hideCancel?: boolean;
}
export function PromptedButton(props: Props) {
  const [openPrompt, setOpenPrompt] = useState(false);

  const tryFn = () => (props.needsPrompt ? setOpenPrompt(true) : props.okFn());
  const reset = useCallback(() => {
    props.okFn();
    setOpenPrompt(false);
  }, [props]);

  const classes = useMemo(
    () =>
      "j-button " +
      mapColorToClass(props.color) +
      " " +
      mapBgColorToClass(props.color) +
      " " +
      props.className +
      (props.disabled ? " j-button-disabled" : ""),
    [props.className, props.color, props.disabled]
  );

  return (
    <>
      <button
        className={classes}
        color={props.color}
        onClick={tryFn}
        disabled={props.disabled}
      >
        {props.startIcon}
        {props.children}
      </button>
      <Prompter
        promptText={props.promptText ?? "OK?"}
        okFn={reset}
        cancelFn={() => setOpenPrompt(false)}
        open={openPrompt}
        okText={props.okText}
        thirdFn={props.thirdFn}
        thirdText={props.thirdText}
        cancelText={props.cancelText}
        hideCancel={props.hideCancel}
      />
    </>
  );
}
