import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchSchieberDetails } from "../../games/gamesActionCreators";
import { SchieberSession } from "../../games/gamesReducer";
import { selectSchieber } from "../../games/gamesSelector";
import { RootState } from "../../global/Store";
import { AdminRequired } from "../Common/AdminRequired";
import { SchieberDraftsTable } from "./SchieberDraftsTable";
import { SchieberTimeline2 } from "./Timeline/SchieberTimeline2";
import { SchieberStrichliJassTafel } from "./SchieberStrichliJassTafel";
import { Button, Paper } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { SchieberLineGraph2 } from "./SchieberLineGraph2";
import { useJassereiFormStyles } from "../Common/useJassereiFormStyles";
import { Image } from "../Common/Image";

interface Params {
  id: string;
}
export function SchieberDetails() {
  const dispatch = useDispatch();

  const { id } = useParams<Params>();
  const schieber = useSelector((state: RootState) =>
    selectSchieber(state, Number(id))
  );

  useEffect(() => {
    if (schieber?.sessionId) {
      dispatch(fetchSchieberDetails(schieber.sessionId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return schieber ? (
    schieber?.hand?.length ? (
      <SchieberDetailsInternal schieber={schieber}></SchieberDetailsInternal>
    ) : (
      <div>Laden...</div>
    )
  ) : (
    <div>Schieber ned gfonde</div>
  );
}

interface Props {
  schieber: SchieberSession;
}
function SchieberDetailsInternal(props: Props) {
  const classes = useJassereiFormStyles();
  const stateToClipboard = useCallback(() => {
    const json = JSON.stringify(props.schieber, undefined, 2);
    navigator.clipboard.writeText(json);
  }, [props]);

  return (
    <>
      <Link to={"/schiebers"}>
        <Button
          variant="contained"
          className={classes.root}
          color="secondary"
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        >
          Öbersecht
        </Button>
      </Link>
      <Paper elevation={3} className={"matchblatt"}>
        <SchieberTimeline2 session={props.schieber} />
      </Paper>
      <SchieberStrichliJassTafel session={props.schieber} />
      <Paper elevation={3} className={"matchblatt"}>
        <SchieberLineGraph2 schieber={props.schieber} />
      </Paper>
      <Paper elevation={3} className={"matchblatt"}>
        <SchieberDraftsTable session={props.schieber} />
      </Paper>
      {props?.schieber?.attachment && (
        <Paper elevation={3} className={"matchblatt"}>
          <Image size="l" guid={props.schieber.attachment} />
        </Paper>
      )}
      <AdminRequired silent>
        <div onClick={stateToClipboard}>&lt;&gt;</div>
      </AdminRequired>
    </>
  );
}
