import React from "react";
import "../../App.scss";

interface Props {
  click: () => void;
  children: any;
}
export function Body(props: Props) {
  return (
    <div role="main" className="mainContent container" onClick={props.click}>
      {props.children}
    </div>
  );
}
