import { AT } from "../global/actionTypes";
import { Club } from "../types/types";

export interface ClubsState {
  clubs?: Club[];
}

export const clubsReducer = (
  state: ClubsState = { clubs: undefined },
  action: any
): ClubsState => {
  switch (action.type) {
    case AT.PUT_CLUBS: {
      return { ...state, clubs: action.payload };
    }
    case AT.ANNOUNCE_NEWCLUB: {
      return { ...state };
    }
  }
  return { ...state };
};
