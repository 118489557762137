import React, { useCallback } from "react";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PersonalSession, Player } from "../../types/types";
import { PlayerWidget } from "../Common/PlayerWidget";
import { useHistory } from "react-router";
interface Props {
  player: Player;
}
export function PlayerSessions(props: Props) {
  return props.player.sessions ? (
    <PlayerSessionsInternal sessions={props.player.sessions} />
  ) : (
    <div>Keine Sessions gefunden</div>
  );
}
interface InternalProps {
  sessions: PersonalSession[];
}
function PlayerSessionsInternal(props: InternalProps) {
  const history = useHistory();

  const gotoDetails = useCallback(
    (schieber: PersonalSession) =>
      history.push(`/schieber/${schieber.sessionId}`),
    [history]
  );

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th className="d-none d-sm-table-cell"></th>
          <th className="d-none d-sm-table-cell">Datum</th>
          <th className="d-none d-sm-table-cell">Partner</th>
          <th className="d-none d-sm-table-cell">Resultat</th>
          <th className="d-none d-sm-table-cell"># Rundene</th>
        </tr>
      </thead>
      <tbody>
        {props.sessions.map((s: PersonalSession) => (
          <tr onClick={() => gotoDetails(s)} key={s?.sessionId}>
            <td>
              {s.won ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faTimes} />
              )}
            </td>
            <td>{s.started}</td>
            <td>
              <PlayerWidget playerId={s.partnerId} />
            </td>
            <td>
              {s.points}:{s.opponentPoints}
            </td>
            <td className="d-none d-sm-table-cell">{s.hands}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
