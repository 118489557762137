import { SchieberSession } from "../games/gamesReducer";
import { fetchGet, fetchPost } from "../util/apiUtil";

export const loadSchiebers = () => fetchGet("SchieberSessions/GetAll");
export const loadSchieber = (sessionId: string) =>
  fetchGet("SchieberSessions/Get", { sessionId });
export const loadSchieberDrafts = () =>
  fetchGet("SchieberSessions/GetPersonalDrafts");

export const createSchieber = (schieber: SchieberSession) =>
  fetchPost("SchieberSessions/CreateSession", { ...schieber });
export const createSchieberDraft = (schieber: SchieberSession) =>
  fetchPost("SchieberSessions/CreateDraft", {
    data: JSON.stringify(schieber),
    sessionId: schieber.sessionId,
  });

export const approveSchieber = (payload: { id: number; team1Won?: boolean }) =>
  fetchPost("SchieberSessions/ApproveSession", payload);
export const approveSchieberDraft = (payload: {
  id: number;
  team1Won?: boolean;
}) => fetchPost("SchieberSessions/ApproveSessionDraft", payload);
export const deleteSchieber = (sessionId: number) =>
  fetchPost("SchieberSessions/DeleteSession", {
    id: sessionId,
  });
