import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../user/userSelector";

interface Props {
  children?: any;
  silent?: boolean;
}
export function AdminRequired(props: Props) {
  const user = useSelector(selectUser);

  return user?.admin ? (
    props.children
  ) : props.silent ? (
    <></>
  ) : (
    <div>Du hesch do nüt verlore</div>
  );
}
