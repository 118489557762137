import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { putCurrentSchieber } from "../../../games/gamesActionCreators";
import { SchieberDraft } from "../../../games/gamesReducer";
import {
  team1sumreducerFn,
  team2sumreducerFn,
} from "../../../games/gamesSelector";
import { RootState } from "../../../global/Store";
import { playerTitle, selectPlayer } from "../../../players/playerSelector";
import { Prompter } from "../../Common/Prompter";

interface Props {
  draft: SchieberDraft;
}
export function SchieberDraftListDraftRow(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const player = useSelector((state: RootState) =>
    selectPlayer(state, props.draft.creatorId)
  );
  const [promptOpen, setPromptOpen] = useState(false);

  const data = useMemo(() => JSON.parse(props.draft.data), [props.draft.data]);
  const stand1 = useMemo(
    () => data?.hand?.reduce(team1sumreducerFn, 0) ?? "?",
    [data?.hand]
  );
  const stand2 = useMemo(
    () => data?.hand?.reduce(team2sumreducerFn, 0) ?? "?",
    [data?.hand]
  );

  const gotoDetails = useCallback(() => {
    dispatch(putCurrentSchieber({ ...data }));
    history.push("/erfassen");
  }, [dispatch, data, history]);

  return (
    <>
      <tr className="j-subrow" onClick={() => setPromptOpen(true)}>
        <td>
          <FontAwesomeIcon icon={faLongArrowAltRight} />
        </td>
        <td>
          <span className="badge badge-pill badge-primary">
            {player?.shortName}
          </span>
          <span className="badge badge-pill badge-primary j-additional">
            {playerTitle(player)}
          </span>
        </td>
        <td className="j-additional">{props.draft.createdOn}</td>
        <td>{stand1}</td>
        <td>{stand2}</td>
      </tr>
      <Prompter
        open={promptOpen}
        okFn={() => gotoDetails()}
        cancelFn={() => setPromptOpen(false)}
        key={props.draft?.draftId}
        promptText={
          "Wotsch du de Entwurf lade? Ungspeichereti Resultat gönd verlore."
        }
      />
    </>
  );
}
