import React, { useMemo } from "react";
import { SchieberSession } from "../../../games/gamesReducer";
import {
  team1sumreducerFn,
  team2sumreducerFn,
} from "../../../games/gamesSelector";

interface Props {
  session: SchieberSession;
}
export function SchieberTimelineSummary2(props: Props) {
  const team1Points = useMemo(
    () => props?.session?.hand?.reduce(team1sumreducerFn, 0),
    [props.session.hand]
  );
  const team2Points = useMemo(
    () => props?.session?.hand?.reduce(team2sumreducerFn, 0),
    [props.session.hand]
  );

  return (
    <tr className="timeline-total-line">
      <td colSpan={3}>Total</td>
      <td style={{ textAlign: "right" }}>{team1Points}</td>
      <td style={{ textAlign: "right" }}>{team2Points}</td>
    </tr>
  );
}
