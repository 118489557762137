import React from "react";
import { faCrown, faSkull } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./erfassen.scss";

interface Props {
  onInput: (asaeger: number, gegner: number) => any;
  isMatch?: boolean;
  isKonterMatch?: boolean;
}
export function RegisterSchieberMatch(props: Props) {
  const match = () => props.onInput(257, 0);
  const konterMatch = () => props.onInput(0, 257);

  return (
    <div className={"matchasaege"}>
      <div
        className={props.isMatch ? "matchAktiv" : "matchInAktiv"}
        onClick={match}
      >
        <FontAwesomeIcon icon={faCrown} />
      </div>
      <div
        className={props.isKonterMatch ? "matchAktiv" : "matchInAktiv"}
        onClick={konterMatch}
      >
        <FontAwesomeIcon icon={faSkull} />
      </div>
    </div>
  );
}
