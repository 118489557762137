import React from "react";

interface Props {
  val?: number;
  submit: (val?: number) => any;
  type?: "list" | "square";
  children: any;
}
export function SelectKachel(props: Props) {
  return (
    <div
      className={"kachel"}
      style={{
        width: props.type === "square" ? "100px" : "200px",
        height: props.type === "square" ? "100px" : "8vh",
      }}
      onClick={() => props.submit(props.val)}
    >
      {props.children}
    </div>
  );
}
