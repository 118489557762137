import React, { useEffect } from "react";
import { currentSubDomain, getMainUrl } from "./util/commonUtil";
import ClubApp from "./ClubApp";
import PortalApp from "./PortalApp";
import { useDispatch, useSelector } from "react-redux";
import { selectClubs } from "./clubs/clubsSelector";
import "./App.scss";
import { fetchClubs } from "./clubs/clubsActionCreators";
import { fetchClubDetails } from "./clubdetails/clubdetailsActionCreators";

function App() {
  const subdomain = currentSubDomain();

  const clubs = useSelector(selectClubs);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchClubs());
    if (subdomain) {
      dispatch(fetchClubDetails(subdomain));
    }
  }, [dispatch, subdomain]);

  useEffect(() => {
    if (
      !subdomain || // wenn noch nicht im club
      !clubs || // wenn noch nicht geladen
      clubs.some((c) => c.shortName?.toLowerCase() === subdomain?.toLowerCase()) // wenn club existiert
    ) {
      // OK
      return;
    }
    // TODO: not working properly
    document.location = getMainUrl();
  }, [clubs, subdomain]);

  return (
    <>
      {!clubs ? (
        <>Bitte warte...</>
      ) : subdomain ? (
        <ClubApp subdomain={subdomain} />
      ) : (
        <PortalApp />
      )}
    </>
  );
}

export default App;
