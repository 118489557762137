import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPartner,
  selectPointsByHandAndUser,
  selectTeamNo,
} from "../../../games/gamesSelector";
import { RootState } from "../../../global/Store";
import { selectPlayer } from "../../../players/playerSelector";
import {
  asaege,
  putVariante,
  schiebe,
} from "../../../games/gamesActionCreators";
import { SchieberHand } from "../../../games/gamesReducer";
import { RegisterSchieberHandAsaegeAmount } from "./RegisterSchieberHandAsaegeAmount";
import { RegisterSchieberHandAsaegeFarb } from "./RegisterSchieberHandAsaegeFarb";
import { RegisterSchieberHandSchieber } from "./RegisterSchieberHandSchieber";
import { RegisterSchieberHandAsaeger } from "./RegisterSchieberHandAsaeger";

import "./erfassen.scss";

interface Props {
  hand: SchieberHand;
}
export function RegisterSchieberHandAsaege(props: Props) {
  const dispatch = useDispatch();

  const [focusInput, setFocusInput] = useState(false);
  const dra = useSelector((state: RootState) =>
    selectPlayer(state, props.hand.playerDragsiId)
  );
  const gschobe = useSelector((state: RootState) =>
    selectPlayer(state, props.hand?.playerGschobeId)
  );
  const partnerId = useSelector((state: RootState) =>
    selectPartner(state, dra?.userId)
  );
  const points = useSelector((state: RootState) =>
    selectPointsByHandAndUser(state, props.hand, dra?.userId)
  );
  const teamNo = useSelector((state: RootState) =>
    selectTeamNo(state, dra?.userId)
  );

  const isMatch = useMemo(
    () =>
      (teamNo === 1 && props.hand.team1Result === 257) ||
      (teamNo === 2 && props.hand.team2Result === 257),
    [props.hand.team1Result, props.hand.team2Result, teamNo]
  );
  const isKonterMatch = useMemo(
    () =>
      (teamNo === 1 && props.hand.team2Result === 257) ||
      (teamNo === 2 && props.hand.team1Result === 257),
    [props.hand.team1Result, props.hand.team2Result, teamNo]
  );

  const onPointsChanged = useCallback(
    (asaegerPoints: number, gegnerPoints: number) => {
      dispatch(
        asaege(
          props.hand,
          teamNo === 1 ? asaegerPoints : gegnerPoints,
          teamNo === 1 ? gegnerPoints : asaegerPoints
        )
      );
    },
    [dispatch, props.hand, teamNo]
  );
  const schiebeFn = useCallback(() => {
    dispatch(schiebe(props.hand, gschobe ? undefined : partnerId));
  }, [dispatch, gschobe, partnerId, props.hand]);
  const chooseVariante = useCallback(
    (variante: number) => {
      dispatch(putVariante(props.hand, variante));
      setFocusInput(true);
    },
    [dispatch, props.hand]
  );

  const farbe = useMemo(
    () =>
      [1, 2, 3, 4, 5, 6].map((no: number) => (
        <RegisterSchieberHandAsaegeFarb
          key={no}
          {...props}
          variante={no}
          chooseVariante={chooseVariante}
        />
      )),
    [props, chooseVariante]
  );

  return (
    <>
      <RegisterSchieberHandAsaeger {...props} />
      <RegisterSchieberHandAsaegeAmount
        onInput={onPointsChanged}
        amount={points}
        isMatch={isMatch}
        isKonterMatch={isKonterMatch}
        focus={focusInput}
        setFocus={setFocusInput}
      />
      {farbe}
      <RegisterSchieberHandSchieber {...props} schiebe={schiebeFn} />
    </>
  );
}
