import React, { useCallback, useState } from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import { fetchChangePassword } from "../../user/userActionCreators";
import { useJassereiFormStyles } from "../Common/useJassereiFormStyles";

export function ChangePassword() {
  const styles = useJassereiFormStyles();
  const dispatch = useDispatch();

  const [state, setState] = useState({ oldPassword: "", newPassword: "" });

  const handleChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setState({
        ...state,
        [name]: value,
      });
    },
    [state]
  );
  const change = useCallback(
    (e: any) => {
      dispatch(fetchChangePassword(state));
    },
    [dispatch, state]
  );

  return (
    <>
      <Typography variant="h5" component="h2">
        Kennwort ändere
      </Typography>
      <form noValidate autoComplete="off" className={styles.root}>
        <TextField
          type="password"
          size="small"
          id="oldPassword"
          name="oldPassword"
          label="Alts Kennwort"
          variant="outlined"
          onChange={handleChange}
          value={state.oldPassword}
          onSubmit={change}
        />
        <TextField
          type="password"
          size="small"
          id="newPassword"
          name="newPassword"
          label="Neus Kennwort"
          variant="outlined"
          onChange={handleChange}
          value={state.newPassword}
          onSubmit={change}
        />
      </form>
      <Button
        className={styles.root}
        variant="contained"
        color="primary"
        onClick={change}
        disabled={state.oldPassword === state.newPassword}
        startIcon={<FontAwesomeIcon icon={faUserLock} />}
      >
        Ändere
      </Button>
    </>
  );
}
