import { RootState } from "../global/Store";
import { selectTeamTitle } from "../players/playerSelector";
import { SchieberHand } from "./gamesReducer";

export const selectCurrentUiIndex = (state: RootState) =>
  state.games.currentUiIndex;

export const selectSchieber = (state: RootState, id: number) =>
  state.games.allSchieberSessions?.find((s) => s.sessionId === id);
export const selectSchiebers = (state: RootState) =>
  state.games.allSchieberSessions;

export const selectEditSchieber = (state: RootState) =>
  state.games.currentSchieberSession;

export const selectInitSchieberValid = (state: RootState) =>
  state.games.currentSchieberSession.pointsGoal > 100 &&
  Boolean(state.games.currentSchieberSession.seasonId) &&
  Boolean(state.games.currentSchieberSession.team1.player1Id) &&
  Boolean(state.games.currentSchieberSession.team1.player2Id) &&
  Boolean(state.games.currentSchieberSession.team2.player1Id) &&
  Boolean(state.games.currentSchieberSession.team2.player2Id);

export const selectIsGoalReached = (state: RootState) =>
  selectTeam1GoalReached(state) || selectTeam2GoalReached(state);

export const selectTeam1GoalReached = (state: RootState) =>
  team1totalpoints(state) >= state.games.currentSchieberSession.pointsGoal;
export const selectTeam2GoalReached = (state: RootState) =>
  team2totalpoints(state) >= state.games.currentSchieberSession.pointsGoal;

export const pendingChanges = (state: RootState) =>
  state.games.pendingChanges &&
  Boolean(state.games.currentSchieberSession?.sessionId);

export const selectSchieberHand = (state: RootState, id: number) =>
  state.games.currentSchieberSession.hand?.find((s) => s.no === id);

export const selectLastHand = (state: RootState) =>
  state.games.currentSchieberSession.hand[
    state.games.currentSchieberSession.hand.length - 1
  ];
export const selectHandNo = (state: RootState) =>
  state.games.currentSchieberSession.hand.length;

export const isHandValid = (hand: SchieberHand | undefined) =>
  Boolean(
    (hand?.team1Result || hand?.team2Result) &&
      hand?.variante &&
      hand?.playerDragsiId
  );

export const selectPartner = (state: RootState, id?: number) => {
  const team1 = state.games.currentSchieberSession.team1;
  const team2 = state.games.currentSchieberSession.team2;
  return team1.player1Id === id
    ? team1.player2Id
    : team1.player2Id === id
    ? team1.player1Id
    : team2.player1Id === id
    ? team2.player2Id
    : team2.player2Id
    ? team2.player1Id
    : undefined;
};
export const selectTeamNo = (state: RootState, userId?: number) => {
  const team1 = state.games.currentSchieberSession.team1;
  const team2 = state.games.currentSchieberSession.team2;
  return team1.player1Id === userId || team1.player2Id === userId
    ? 1
    : team2.player1Id === userId || team2.player2Id === userId
    ? 2
    : undefined;
};

export const selectTeam1Title = (state: RootState) =>
  selectTeamTitle(state, state.games.currentSchieberSession.team1);
export const selectTeam2Title = (state: RootState) =>
  selectTeamTitle(state, state.games.currentSchieberSession.team2);

export const selectPointsByHandAndUser = (
  state: RootState,
  currentHand: SchieberHand,
  userId?: number
) => {
  const teamNo = selectTeamNo(state, userId);
  return (
    (teamNo === 1
      ? currentHand.team1Result
      : teamNo === 2
      ? currentHand.team2Result
      : 0) ?? 0
  );
};

export const weisReducerFn = (
  accumulator: number,
  currentWeis: { betrag: number; playerId: number }
) => accumulator + (currentWeis?.betrag ?? 0);

export const team1sumreducerFn = (
  accumulator: number,
  currentHand: SchieberHand
) =>
  accumulator +
  ((currentHand?.team1Result ?? 0) +
    (currentHand?.team1Wiis?.reduce(weisReducerFn, 0) ?? 0)) *
    varianteToFactor(currentHand.variante);

export const team2sumreducerFn = (
  accumulator: number,
  currentHand: SchieberHand
) =>
  accumulator +
  ((currentHand?.team2Result ?? 0) +
    (currentHand?.team2Wiis?.reduce(weisReducerFn, 0) ?? 0)) *
    varianteToFactor(currentHand.variante);

export const team1sumreducerlightFn = (
  accumulator: number,
  currentHand: SchieberHand
) =>
  accumulator +
  (currentHand?.team1Result ?? 0) * varianteToFactor(currentHand.variante);
export const team2sumreducerlightFn = (
  accumulator: number,
  currentHand: SchieberHand
) =>
  accumulator +
  (currentHand?.team2Result ?? 0) * varianteToFactor(currentHand.variante);

export const team1totalpoints = (state: RootState) =>
  state.games.currentSchieberSession.hand?.reduce(team1sumreducerFn, 0);

export const team2totalpoints = (state: RootState) =>
  state.games.currentSchieberSession.hand?.reduce(team2sumreducerFn, 0);

export const currentStandingWidget = (state: RootState) =>
  state.games.currentSchieberSession.hand?.reduce(team2sumreducerFn, 0) +
  " : " +
  state.games.currentSchieberSession.hand?.reduce(team1sumreducerFn, 0) +
  " (Runde " +
  state.games.currentSchieberSession.hand?.length +
  ")";

export const varianteToFactor = (variante?: number) => {
  switch (variante) {
    case 0:
    case 1:
    case 2:
      return 1;
    case 3:
    case 4:
      return 2;
    case 5:
    case 6:
      return 3;
    default:
      return 1;
  }
};
