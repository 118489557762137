import React, { useState } from "react";
import { faArrowRight, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Infos } from "../Admin/Infos";

function ClubAbout() {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <h2>Jasserei.ch</h2>
      <p>Das Portal esch es Gschänk a ali statistisch interessierte Jasser</p>
      <code>Made by Puurle &#169;2021-2022</code>
      <hr />
      {visible ? (
        <Infos />
      ) : (
        <>
          <div onClick={() => setVisible(true)}>
            <FontAwesomeIcon icon={faArrowRight} />
            &nbsp;&nbsp;Meh&nbsp;&nbsp;
            <FontAwesomeIcon icon={faInfoCircle} />
          </div>
        </>
      )}
    </>
  );
}
export default ClubAbout;
