import React from "react";
import "./common.scss";

interface Props {
  children: any;
}
export const JasstafelZContainer = (props: Props) => (
  <div className={"jasstafel jasstafel_z"}>
    <div className={"tafelcontent"}>{props.children}</div>
  </div>
);
