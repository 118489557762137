import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { putAuthData } from "../../user/userActionCreators";
import { useLocation } from "react-router-dom";
interface LooseObject {
  [key: string]: any;
}

interface Props {
  children: any;
}
export const Enter = (props: Props) => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const auth = React.useMemo(() => {
    const result: LooseObject = {};
    const params = new URLSearchParams(search);
    const keys = params.keys();
    let key = keys.next();
    while (key.value) {
      result[key.value] = params.get(key.value);
      key = keys.next();
    }
    return result;
  }, [search]);

  useEffect(() => {
    dispatch(putAuthData(auth));
  }, [auth, dispatch]);
  return <>{props.children}</>;
};
