import React from "react";
import { SchieberHand } from "../../../games/gamesReducer";
import { PlayerWidget } from "../../Common/PlayerWidget";

interface Props {
  hand: SchieberHand;
}
export function SchieberTimelineWeis(props: Props) {
  return (
    <>
      {props.hand.team1Wiis.map((weis, index) => (
        <tr className="timeline-wiis" key={index}>
          <td>{weis.betrag}</td>
          <td colSpan={2}>
            <div className="timeline-wiis-team1">
              <PlayerWidget playerId={weis.playerId} />
            </div>
          </td>
        </tr>
      ))}
      {props.hand.team2Wiis.map((weis, index) => (
        <tr className="timeline-wiis" key={index}>
          <td colSpan={2}>
            <div className="timeline-wiis-team2">
              <PlayerWidget playerId={weis.playerId} />
            </div>
          </td>
          <td>{weis.betrag}</td>
        </tr>
      ))}
    </>
  );
}
