import { useCallback, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useJassereiFormStyles } from "../Common/useJassereiFormStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { selectClubdetails } from "../../clubdetails/clubdetailsSelector";
import { fetchUpdateClubdetails } from "../../clubdetails/clubdetailsActionCreators";
import { UploadImage } from "../Common/UploadImage";

const url = "club/AttachMedia";

export function ManageClub() {
  const styles = useJassereiFormStyles();
  const dispatch = useDispatch();

  const storeClub = useSelector(selectClubdetails);
  const [club, setClub] = useState(storeClub);
  const [saved, setSaved] = useState(true);

  const handleChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      if (club) {
        setClub({
          ...club,
          [name]: value,
        });
      }
      setSaved(false);
    },
    [club]
  );
  const save = useCallback(
    (e: any) => {
      if (club) {
        dispatch(fetchUpdateClubdetails(club));
      }
      setSaved(true);
    },
    [club, dispatch]
  );

  return (
    <>
      <h3>Club bearbeite</h3>
      <form noValidate autoComplete="off" className={styles.root}>
        <TextField
          id="clubName"
          name="clubName"
          label="Vereinsname"
          variant="outlined"
          onChange={handleChange}
          value={club?.clubName}
        />
        <TextField
          id="longName"
          name="longName"
          label="Ausgeschrieben"
          variant="outlined"
          onChange={handleChange}
          value={club?.longName}
        />
        <TextField
          id="shortName"
          name="shortName"
          label="Kurzname"
          variant="outlined"
          onChange={handleChange}
          value={club?.shortName}
        />
        <TextField
          id="contactEmail"
          name="contactEmail"
          label="Kontakt Email"
          variant="outlined"
          onChange={handleChange}
          value={club?.contactEmail}
        />
        <TextField
          id="contactName"
          name="contactName"
          label="Vorname"
          variant="outlined"
          onChange={handleChange}
          value={club?.contactName}
        />
        <TextField
          id="contactLastName"
          name="contactLastName"
          label="Nachname"
          variant="outlined"
          onChange={handleChange}
          value={club?.contactLastName}
        />
        <TextField
          id="contactAdress"
          name="contactAdress"
          label="Kontaktadresse"
          variant="outlined"
          onChange={handleChange}
          value={club?.contactAdress}
        />
        <hr />
        <Button
          className={styles.root}
          variant="contained"
          color="primary"
          onClick={save}
          disabled={saved}
          startIcon={<FontAwesomeIcon icon={faSave} />}
        >
          Speichere
        </Button>
        <UploadImage
          callBackFn={(guid: string) => {
            handleChange({ target: { name: "thumbnail", value: guid } });
          }}
          url={url}
          additionalParams={[{ key: "type", value: "ClubThumbnail" }]}
          mediaGuid={club?.thumbnail}
          size="l"
        >
          Vereinssymbol hochladen
        </UploadImage>
        <UploadImage
          callBackFn={(guid: string) =>
            handleChange({ target: { name: "mainImage", value: guid } })
          }
          url={url}
          additionalParams={[{ key: "type", value: "ClubMainImage" }]}
          mediaGuid={club?.mainImage}
          size="max"
        >
          Vereinsbild hochladen
        </UploadImage>
      </form>
    </>
  );
}
