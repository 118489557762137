import React, { useCallback, useState } from "react";
import { Button, Checkbox, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { RootState } from "../../global/Store";
import { useJassereiFormStyles } from "../Common/useJassereiFormStyles";
import { selectSeason } from "../../admin/adminSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faSave } from "@fortawesome/free-solid-svg-icons";
import { Season } from "../../seasons/seasonReducer";
import { putSeason, updateSeason } from "../../seasons/seasonActionCreators";

interface Params {
  id: string;
}
export function ManageSeason() {
  const { id } = useParams<Params>();
  const season = useSelector((state: RootState) =>
    selectSeason(state, Number(id))
  );

  return season ? (
    <ManageSeasonInternal season={season}></ManageSeasonInternal>
  ) : (
    <div>COULD NOT FIND</div>
  );
}

interface Props {
  season: Season;
}
function ManageSeasonInternal(props: Props) {
  const styles = useJassereiFormStyles();
  const dispatch = useDispatch();

  const [saved, setSaved] = useState(true);

  const handleChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      dispatch(
        putSeason({
          ...props.season,
          [name]: value,
        })
      );
      setSaved(false);
    },
    [dispatch, props.season]
  );
  const handleBoolChange = useCallback(
    (e: any) => {
      const { name, checked } = e.target;
      dispatch(
        putSeason({
          ...props.season,
          [name]: checked,
        })
      );
      setSaved(false);
    },
    [dispatch, props.season]
  );
  const save = useCallback(
    (e: any) => {
      dispatch(updateSeason(props.season));
      setSaved(true);
    },
    [dispatch, props.season]
  );

  return (
    <>
      <h3>Saison bearbeite</h3>
      <form noValidate autoComplete="off" className={styles.root}>
        <TextField
          id="name"
          name="name"
          label="Bezeichnig"
          variant="outlined"
          onChange={handleChange}
          value={props?.season?.name}
        />
        <Checkbox
          name="active"
          checked={props?.season?.active}
          onChange={handleBoolChange}
        />
        {"Aktiv"}
        <hr />
        <Button
          className={styles.root}
          variant="contained"
          color="primary"
          onClick={save}
          disabled={saved}
          startIcon={<FontAwesomeIcon icon={faSave} />}
        >
          Speichere
        </Button>
        <Link to={"/admin/seasons"}>
          <Button
            className={styles.root}
            variant="contained"
            color="secondary"
            startIcon={<FontAwesomeIcon icon={faList} />}
          >
            Öbersecht
          </Button>
        </Link>
      </form>
    </>
  );
}
