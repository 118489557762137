import React, { useCallback, useEffect } from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { RootState } from "../../global/Store";
import { fetchPlayerDetails } from "../../players/playerActionCreators";
import { selectPlayer } from "../../players/playerSelector";
import { Player } from "../../types/types";
import { AdminRequired } from "../Common/AdminRequired";
import { useJassereiFormStyles } from "../Common/useJassereiFormStyles";
import { PlayerProfile } from "./PlayerProfile";
import { PlayerSessions } from "./PlayerSessions";

interface Params {
  id: string;
}
export function PlayerDetails() {
  const { id } = useParams<Params>();
  const player = useSelector((state: RootState) =>
    selectPlayer(state, Number(id))
  );

  return player ? (
    <PlayerDetailsInternal player={player}></PlayerDetailsInternal>
  ) : (
    <div>Spieler konnte nicht gefunden werden</div>
  );
}

interface Props {
  player: Player;
}
function PlayerDetailsInternal(props: Props) {
  const classes = useJassereiFormStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPlayerDetails(props.player));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stateToClipboard = useCallback(() => {
    const json = JSON.stringify(props.player, undefined, 2);
    navigator.clipboard.writeText(json);
  }, [props]);

  return (
    <>
      <Link to={"/players"}>
        <Button
          className={classes.root}
          variant="contained"
          color="secondary"
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
        >
          Öbersecht
        </Button>
      </Link>
      <PlayerProfile player={props.player} />
      <Paper className={"matchblatt"}>
        <PlayerSessions player={props.player}></PlayerSessions>
      </Paper>
      <AdminRequired silent>
        <div onClick={stateToClipboard}>&lt;&gt;</div>
      </AdminRequired>
    </>
  );
}
